<template>
  <fieldset>
    <validation-provider
      v-slot="{ errors }"
      :rules="{required: oppRoute}"
      name="probability"
    >
      <b-field
        horizontal
        label="Probability"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        :class="{'is-required': oppRoute}"
      >
        <div class="columns">
          <div class="column is-half">
            <b-select
              v-model="probability"
              expanded
            >
              <option
                v-for="option in probabilities"
                :key="option.id"
                :value="option.code"
              >
                {{ option.value }}
              </option>
            </b-select>
          </div>
        </div>
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      rules="required|integer"
      name="estimated revenue"
    >
      <b-field
        horizontal
        label="Estimated Revenue"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        class="is-required"
      >
        <div class="columns">
          <div class="column is-half">
            <currency-input
              v-model="estimatedRevenue"
              class="input"
              @input="setGrossProfit"
            />
          </div>
        </div>
      </b-field>
    </validation-provider>

    <validation-provider
      v-if="estimatedRevenue && estimatedRevenue > 0"
      v-slot="{ errors }"
      :rules="{required: oppRoute}"
      name="est. gross profit %"
    >
      <b-field
        horizontal
        label="Est. Gross Profit %"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        :class="{'is-required': oppRoute}"
      >
        <div class="columns">
          <div class="column is-2">
            <b-numberinput
              v-model="estGrossProfitPercentage"
              :controls="false"
              :step="0.01"
              @input="setGrossProfit"
            />
          </div>

          <div class="column is-4">
            <b-slider
              v-model="estGrossProfitPercentage"
              type="is-info"
              :min="0"
              :max="100"
              format="percent"
              @input="setGrossProfit"
            />
          </div>
        </div>
      </b-field>
    </validation-provider>

    <validation-provider
      v-if="estimatedRevenue && estimatedRevenue > 0"
      v-slot="{ errors }"
      :rules="{required: oppRoute}"
      name="estimated gross profit"
    >
      <b-field
        horizontal
        label="Estimated Gross Profit"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        :class="{'is-required': oppRoute}"
      >
        <div class="columns">
          <div class="column is-half">
            <currency-input
              v-model="estimatedGrossProfit"
              class="input"
              @change="setGrossProfitPercentage"
            />
          </div>
        </div>
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="Bid Due Date"
      label-for="bidDueDate"
    >
      <div class="columns">
        <div class="column is-half">
          <b-input
            id="bidDueDate"
            v-model="bidDueDate"
            type="date"
            icon="calendar"
          />
        </div>
      </div>
    </b-field>

    <validation-provider
      v-slot="{ errors }"
      :rules="{required: oppRoute, beforeDate: { other: estInstallDate } }"
      name="est. booking date"
    >
      <b-field
        horizontal
        label="Est. Booking Date"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        :class="{'is-required': oppRoute}"
      >
        <div class="columns">
          <div class="column is-half">
            <b-input
              v-model="estBookingDate"
              type="date"
              icon="calendar"
            />
          </div>
        </div>
      </b-field>
    </validation-provider>

    <validation-provider
      v-slot="{ errors }"
      :rules="{required: oppRoute }"
      name="est. install date"
    >
      <b-field
        horizontal
        label="Est. Install Date (Post - Est. Booking Date)"
        :type="{ 'is-danger': errors[0] }"
        :message="errors"
        :class="{'is-required': oppRoute}"
      >
        <div class="columns">
          <div class="column is-half">
            <b-input
              v-model="estInstallDate"
              type="date"
              icon="calendar"
            />
          </div>
        </div>
      </b-field>
    </validation-provider>

    <b-field
      horizontal
      label="Est. Invoice Date"
    >
      <div class="columns">
        <div class="column is-half">
          <b-input
            v-model="estInvoiceDate"
            type="date"
            icon="calendar"
          />
        </div>
      </div>
    </b-field>
  </fieldset>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'OpportunitiesEstimatedPotentialsForm',

  components: {
    ValidationProvider,
  },

  props: {
    probabilities: {
      type: Array,
      required: true,
    },
  },

  computed: {
    oppRoute() {
      return this.$route.name === 'AddOpportunity' || this.$route.name === 'EditOpportunity';
    },

    probability: {
      get() {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.state.Leads.lead.estimatedPotentials.probability;
        }
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.probability;
      },

      set(value) {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.commit('Leads/SET_LEAD_PROBABILITY', value);
        }
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_PROBABILITY', value);
      },
    },

    estimatedRevenue: {
      get() {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.state.Leads.lead.estimatedPotentials.estimatedRevenue;
        }
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estimatedRevenue;
      },

      set(value) {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.commit('Leads/SET_LEAD_ESTIMATED_REVENUE', value);
        }
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_ESTIMATED_REVENUE', value);
      },
    },

    estimatedGrossProfit: {
      get() {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.state.Leads.lead.estimatedPotentials.estimatedGrossProfit;
        }
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estimatedGrossProfit;
      },

      set(value) {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.commit('Leads/SET_LEAD_ESTIMATED_GROSS_PROFIT', value);
        }
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_ESTIMATED_GROSS_PROFIT', value);
      },
    },

    estGrossProfitPercentage: {
      get() {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return parseFloat(this.$store.state.Leads.lead.estimatedPotentials
            .estGrossProfitPercentage);
        }
        return parseFloat(this.$store.state.Opportunities.opportunity.estimatedPotentials
          .estGrossProfitPercentage);
      },

      set(value) {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.commit('Leads/SET_LEAD_EST_GROSS_PROFIT_PERCENTAGE', parseFloat(value, 10));
        }
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_EST_GROSS_PROFIT_PERCENTAGE',
          parseFloat(value),
        );
      },
    },

    bidDueDate: {
      get() {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.state.Leads.lead.estimatedPotentials.bidDueDate;
        }
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.bidDueDate;
      },

      set(value) {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.commit('Leads/SET_LEAD_BID_DUE_DATE', value);
        }
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_BID_DUE_DATE', value);
      },
    },

    estBookingDate: {
      get() {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.state.Leads.lead.estimatedPotentials.estBookingDate;
        }
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estBookingDate;
      },

      set(value) {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.commit('Leads/SET_LEAD_ESTIMATED_EST_BOOKING_DATE', value);
        }
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_EST_BOOKING_DATE', value);
      },
    },

    estInstallDate: {
      get() {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.state.Leads.lead.estimatedPotentials.estInstallDate;
        }
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estInstallDate;
      },

      set(value) {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.commit('Leads/SET_LEAD_EST_INSTALL_DATE', value);
        }
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_EST_INSTALL_DATE', value);
      },
    },

    estInvoiceDate: {
      get() {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.state.Leads.lead.estimatedPotentials.estInvoiceDate;
        }
        return this.$store.state.Opportunities.opportunity.estimatedPotentials.estInvoiceDate;
      },

      set(value) {
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          return this.$store.commit('Leads/SET_LEAD_EST_INVOICE_DATE', value);
        }
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_EST_INVOICE_DATE', value);
      },
    },
  },

  methods: {
    setGrossProfit() {
      if (this.estimatedRevenue > 0) {
        const estimatedGrossProfit = (this.estGrossProfitPercentage / 100) * this.estimatedRevenue;
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          this.$store.commit(
            'Leads/SET_LEAD_ESTIMATED_GROSS_PROFIT',
            estimatedGrossProfit,
          );
        } else {
          this.$store.commit(
            'Opportunities/SET_OPPORTUNITY_ESTIMATED_GROSS_PROFIT',
            estimatedGrossProfit,
          );
        }
      }
    },

    setGrossProfitPercentage() {
      if (this.estimatedRevenue > 0) {
        const estGrossProfitPerc = (this.estimatedGrossProfit / this.estimatedRevenue) * 100;
        if (this.$route.name === 'AddLead' || this.$route.name === 'EditLead') {
          this.$store.commit(
            'Leads/SET_LEAD_EST_GROSS_PROFIT_PERCENTAGE',
            estGrossProfitPerc.toFixed(2),
          );
        } else {
          this.$store.commit(
            'Opportunities/SET_OPPORTUNITY_EST_GROSS_PROFIT_PERCENTAGE',
            estGrossProfitPerc.toFixed(2),
          );
        }
      }
    },
  },
};
</script>

<style></style>
