<template>
  <div>
    <div class="content">
      <b-button
        v-if="checkedRows.length > 0"
        type="is-info"
        icon-left="download"
        @click="importSelectedContacts"
      >
        import selected contacts
      </b-button>
    </div>

    <table-header
      :is-drilldown="false"
      :fields="exportFields"
      :data="outlookContacts"
      @search="searchContact"
    >
      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0 || searchClause"
          size="is-small"
          icon-left="power-off"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isBordered"
                size="is-small"
              >
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isCheckable"
                size="is-small"
              >
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isNarrowed"
                size="is-small"
              >
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.stickyHeaders"
                size="is-small"
              >
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isScrollable"
                size="is-small"
              >
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStriped"
                size="is-small"
              >
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
        >
          <b-button
            slot="trigger"
            icon-left="eye"
            size="is-small"
            icon-right="chevron-down"
          >
            columns
          </b-button>
          <draggable
            v-model="columns"
            group="columns"
            @start="drag=true"
            @end="drag=false"
          >
            <b-dropdown-item
              v-for="column in columns"
              :key="column.id"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                  size="is-small"
                >
                  {{ column.label }}
                </b-checkbox>
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="outlookContactsTable"
      :mobile-cards="false"
      :loading="loading"
      :data="outlookContacts"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template v-if="column.field === 'createdAt' || column.field === 'updatedAt'">
            <b-input
              v-model="props.filters[column.searchField]"
              type="date"
              icon="calendar"
              size="is-small"
            />
          </template>

          <template v-else>
            <b-input
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />
          </template>
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div
              v-if="
                hasRole('ROLE_BUSINESS_ADMIN') ||
                  isContactOwner(props.row.ownerId) ||
                  isContactManager(props.row.ownerId) "
              class="buttons"
            >
              <b-tooltip
                type="is-info"
                label="Mail"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="envelope"
                  @click="sendMail(
                    props.row.emailAddress, `${props.row.firstName} ${props.row.lastName}`
                  )"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Event"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="clock"
                  @click="addActivity(props.row)"
                />
              </b-tooltip>
            </div>
          </template>

          <template v-else-if="column.field === 'photo'">
            <figure class="image is-32x32">
              <img
                style="width:32px; height:32px;"
                class="is-rounded"
                :src="props.row.photo ? props.row.photo : defaultImage"
                :alt="props.row.firstName"
                @click="$router.push(`/contacts/${props.row.id}/view`)"
              >
            </figure>
          </template>

          <template v-else-if="column.field === 'firstName'">
            <router-link
              class="has-text-primary has-text-weight-bold"
              :to="{ name: 'ViewContact', params: { contactId: props.row.id } }"
            >
              {{
                `
                ${props.row.firstName}
                ${props.row.lastName}
              `.trim()
              }}
            </router-link>
          </template>

          <template v-else-if="column.field === 'emailAddress'">
            <span class="has-text-info has-text-weight-semibold">
              {{ props.row[column.field] | lowercase }}
            </span>
          </template>

          <template v-else-if="column.field === 'accounts'">
            <template v-if="props.row.accounts">
              <div
                v-for="(account, accountIndex) in props.row.accounts"
                :key="accountIndex"
              >
                <b-tag v-if="accountIndex <= 2">
                  {{ account.account.name }}
                </b-tag>
              </div>
              <b-tag v-if="props.row.accounts.length > 3">
                + {{ props.row.accounts.length - 3 }}
              </b-tag>
            </template>
          </template>

          <template
            v-else-if="
              column.field === 'street1' ||
                column.field === 'street2' ||
                column.field === 'street3' ||
                column.field === 'city' ||
                column.field === 'state' ||
                column.field === 'zip'
            "
          >
            {{ props.row.address[column.field] }}
          </template>

          <template v-else>
            {{ props.row[column.field] }}
          </template>
        </template>
      </b-table-column>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                type="is-info"
                size="is-small"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(pageCount, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="pageCount"
                >
                  {{ pageCount }}
                </b-dropdown-item>
                <hr
                  :key="pageCount"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="Outlook Contacts" />
      </template>
    </b-table>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import SearchMixin from '@/mixins/SearchMixin';
import defaultImage from '@/assets/images/avatar.svg';

import {
  TableFooter, TableHeader, EmptyTable,
} from '@/components/Shared';

export default {

  name: 'ContactsTable',

  components: {
    TableFooter,
    TableHeader,
    EmptyTable,
    draggable,
  },

  mixins: [SearchMixin],

  data: () => ({
    defaultImage,

    total: 0,
    page: 1,
    perPage: 50,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    checkedRows: [],

    sortField: 'createdAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    filters: {},

    searchClause: '',

    isEditing: false,
    selectedContactId: false,

    columns: [
      {
        id: '1',
        label: 'Avatar',
        field: 'photo',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: 'photo',
        sticky: false,
        visible: true,
      }, {
        id: '2',
        label: 'Name',
        field: 'firstName',
        sortable: true,
        searchable: true,
        searchField: 'contactname',
        exportField: 'contactname.firstName',
        sticky: false,
        visible: true,
      }, {
        id: '3',
        label: 'Email',
        field: 'emailAddress',
        sortable: true,
        searchable: true,
        searchField: 'email',
        exportField: 'emailAddress',
        sticky: false,
        visible: true,
      }, {
        id: '4',
        label: 'Phone',
        field: 'phone',
        sortable: false,
        searchable: true,
        searchField: 'phone',
        exportField: 'phone',
        sticky: false,
        visible: true,
      }, {
        id: '6',
        label: 'Accounts',
        field: 'accounts',
        sortable: false,
        searchable: true,
        searchField: 'account',
        exportField: 'accounts',
        sticky: false,
        visible: true,
      }, {
        id: '7',
        label: 'Street 1',
        field: 'street1',
        sortable: true,
        searchable: true,
        searchField: 'street1',
        exportField: 'address.street1',
        sticky: false,
        visible: false,
      }, {
        id: '8',
        label: 'Street 2',
        field: 'street2',
        sortable: true,
        searchable: true,
        searchField: 'street2',
        exportField: 'address.street2',
        sticky: false,
        visible: false,
      }, {
        id: '9',
        label: 'Street 3',
        field: 'street3',
        sortable: true,
        searchable: true,
        searchField: 'street3',
        exportField: 'address.street3',
        sticky: false,
        visible: false,
      }, {
        id: '10',
        label: 'City',
        field: 'city',
        sortable: true,
        searchable: true,
        searchField: 'city',
        exportField: 'address.city',
        sticky: false,
        visible: false,
      }, {
        id: '11',
        label: 'State',
        field: 'state',
        sortable: true,
        searchable: true,
        searchField: 'state',
        exportField: 'address.state',
        sticky: false,
        visible: false,
      }, {
        id: '12',
        label: 'Zip',
        field: 'zip',
        sortable: true,
        searchable: true,
        searchField: 'zipcode',
        exportField: 'address.zip',
        sticky: false,
        visible: false,
      }, {
        id: '13',
        label: 'Actions',
        field: 'actions',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: true,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: true,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },
  }),

  computed: {
    ...mapGetters({
      outlookContacts: 'Contacts/getContacts',
      contact: 'Contacts/getContact',
      grid: 'Grids/getGrid',
      loading: 'Contacts/getLoading',
    }),

    exportFields() {
      const data = [...this.columns];
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label, el.exportField]);
      return Object.fromEntries(new Map(result));
    },

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },
  },

  watch: {
    perPage: {
      handler() {
        this.fetchOutlookContacts();
      },
      deep: true,
    },
  },

  async created() {
    try {
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchGrid', {
        gridName: 'outlook_contacts',
        organizationId,
      });
      if (response) {
        this.setGrid();
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          customizations: this.customizations,
          name: 'outlook_contacts',
        });
      }
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    this.filters = this.$route.query;
    this.fetchOutlookContacts();
  },

  destroyed() {
    if (localStorage.getItem('access_token')) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'outlook_contacts',
        customizations: this.customizations,
      });
    }
  },

  methods: {
    /**
     * setGrid
     *
     */
    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex(
          (el) => el.label === a.label,
        ) - this.grid.columns.findIndex((el) => el.label === b.label),
      );
      const newColumns = this.columns.map(
        (el, index) => ({ ...el, visible: this.grid.columns[index].visible }),
      );
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
    },

    /**
     * sendMail
     *
     */
    sendMail(email, subject) {
      window.location.assign(`mailto:${email}?subject=${subject}`);
    },

    addActivity(value) {
      this.$router.push(`/activities/add?contactId=${value.id}&contact=${value.firstName} ${value.lastName}`);
    },

    /**
     * fetchOutlookContacts
     *
     * fetch organization's outlook contacts
     */
    async fetchOutlookContacts() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach((key) => filters[key] === '' && delete filters[key]);
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt).toISOString().substring(0, 10);
        }

        if (Object.keys(filters).includes('updatedondate')) {
          filters.updatedondate = new Date(filters.updatedondate).setDate(
            new Date(filters.updatedondate).getDate(),
          );
          filters.updatedondate = new Date(filters.updatedondate).toISOString().substring(0, 10);
        }
      }

      try {
        const response = await this.$store.dispatch('Contacts/fetchOutlookContacts', {
          page: this.page - 1,
          perPage: this.perPage,
          sort: [this.sortField, this.sortOrder],
          globalSearch: this.searchClause ? `&globalsearch=${this.searchClause}` : '',
          filters: filters && Object.keys(filters).length > 0 ? `&${new URLSearchParams(filters).toString()}` : '',
        });

        this.total = response.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * handle page change events
     * @params { Number } - next page number
     */
    onPageChange(page) {
      this.page = page;
      this.fetchOutlookContacts();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchOutlookContacts();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = filters;
      this.fetchOutlookContacts();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.outlookContactsTable.resetMultiSorting();
      this.$refs.outlookContactsTable.filters = {};
      this.searchClause = '';
      this.filters = {};
      this.fetchOutlookContacts();
    },

    /**
     * onEdit
     *
     * @param {object} contact
     */
    onEdit(contact) {
      this.selectedContactId = contact.id;
      this.$store.commit('Contacts/SET_CONTACT', contact);
      this.isEditing = true;
    },

    /**
     * searchContact
     *
     */
    searchContact(value) {
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchOutlookContacts();
      }, 800);
    },

    /**
     * updateContact
     *
     */
    async updateContact() {
      try {
        const payload = { ...this.contact };
        if (payload.contactPhoto) {
          payload.contactPhoto = payload.contactPhoto.startsWith('http')
            ? null : payload.contactPhoto;
        }
        if (payload.photo) {
          delete payload.photo;
        }
        await this.$store.dispatch('Contacts/updateContact', payload);
        this.isEditing = false;
        this.selectedContactId = '';
        this.$store.commit('Contacts/CLEAR_CONTACT');
        await this.fetchOutlookContacts();
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * deleteContact
     *
     * @param {Number} contactId
     */
    deleteContact(contactId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting contact',
        message: 'Are you sure you want to <b>delete</b> your contact? This action cannot be undone.',
        confirmText: 'Delete Contact',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Contacts/deleteContact', contactId);
          } catch (error) {
            console.error(error);
          }
        },
      });
    },

    importSelectedContacts() {
      const payload = this.checkedRows.map((el) => ({
        contactId: el.id,
      }));
      return this.$buefy.dialog.confirm({
        title: 'Import Selected Contacts',
        message: 'Import all the selected contacts into this account',
        cancelText: 'Disagree',
        confirmText: 'Agree',
        type: 'is-success',
        onConfirm: async () => {
          try {
            await this.$store.dispatch('Contacts/importOutlookContacts', {
              accountId: this.$route.query.accountId,
              contacts: payload,
            });
            await this.$router.push(`/accounts/${this.$route.query.accountId}/view?activeTab=1`);
          } catch (error) {
            console.error(error);
          }
        },
      });
    },
  },

};
</script>
