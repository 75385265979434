<template>
  <section class="section">
    <div class="container">
      <form>
        <b-field>
          <b-upload
            v-model="files"
            multiple
            drag-drop
          >
            <div class="is-fullwidth has-background-light content has-text-centered">
              <b-tag type="is-info">
                browse
              </b-tag>
              <p class="has-text-grey has-text-weight-semibold">
                Drag and Drop Files here.
              </p>
            </div>
          </b-upload>
        </b-field>

        <template v-for="(file, index) in filesList">
          <div
            :key="index"
            class="media"
          >
            <div class="media-content">
              <div class="columns">
                <div class="column">
                  <div class="content">
                    <p>{{ file.name }}</p>
                  </div>
                </div>

                <div class="column is-narrow">
                  <span
                    class="has-text-grey is-pointer"
                    @click.prevent="$emit('remove-file', index)"
                  >
                    <b-icon icon="trash" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </template>

        <br>

        <b-field>
          <div class="buttons">
            <b-button
              :disabled="loading"
              type="is-success"
              icon-left="save"
              @click="$emit('save-files')"
            >
              save
            </b-button>

            <b-button
              type="is-danger"
              @click="$emit('clear-files')"
            >
              cancel
            </b-button>
          </div>
        </b-field>
      </form>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  name: 'DocumentsForm',

  props: {
    filesList: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      loading: 'Documents/getLoading',
    }),

    files: {
      get() {
        return this.$store.state.Documents.files;
      },

      set(value) {
        this.$store.commit('Documents/SET_FILES', value);
      },
    },
  },

  methods: {
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.onload = callback(reader.result);
      reader.readAsDataURL(img);
    },
  },

};
</script>

<style scoped>
.is-fullwidth {
  width: 100%;
  padding: 3rem 1.5rem;
}
</style>
