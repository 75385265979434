<template>
  <div>
    <table-header
      :fields="exportFields"
      :name="'opportunities'"
      @export-xls="exportOpportunities"
      @search="searchOpportunity"
    >
      <template #update-button>
        <b-button
          v-if="isEditing"
          ref="saveEntryButton"
          size="is-small"
          class="mr-4"
          icon-left="save"
          type="is-success"
          @click="updateOpportunity"
        >
          save
        </b-button>
        <b-button
          v-if="isEditing"
          ref="cancelEntryButton"
          size="is-small"
          icon-left="close"
          type="is-success"
          @click="cancelUpdate"
        >
          cancel
        </b-button>
      </template>

      <template #re-assign-button>
        <div>
          <b-button
            v-if="(isAdmin || isSalesManager) && checkedRows.length > 0"
            class="modal-btn"
            size="is-small"
            @click="openModal"
          >
            Re-assign Owner
          </b-button>
        </div>
      </template>

      <template #open-switch-button>
        <b-field>
          <b-switch v-model="openSwitch">
            <small class="has-text-weight-bold">
              {{ openSwitch ? "Show Open" : "Show All" }}
            </small>
          </b-switch>
        </b-field>
      </template>

      <template #reset-button>
        <b-button
          v-if="Object.keys(filters).length > 0"
          size="is-small"
          icon="power-off"
          @click="resetFilters"
        >
          Reset Filters
        </b-button>
      </template>

      <template #customize>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
        >
          <b-button
            slot="trigger"
            size="is-small"
            icon-left="wrench"
            icon-right="chevron-down"
          >
            grids
          </b-button>

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isBordered"
                size="is-small"
              >
                Bordered
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isCheckable"
                size="is-small"
              >
                Checkable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isNarrowed"
                size="is-small"
              >
                Narrowed
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStickyHeaders"
                size="is-small"
              >
                Sticky headers
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isScrollable"
                size="is-small"
              >
                Scrollable
              </b-checkbox>
            </b-field>
          </b-dropdown-item>

          <hr class="dropdown-divider">

          <b-dropdown-item aria-role="listitem">
            <b-field>
              <b-checkbox
                v-model="customizations.isStriped"
                size="is-small"
              >
                Striped
              </b-checkbox>
            </b-field>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #hide-show-columns>
        <b-dropdown
          aria-role="list"
          postition="is-bottom-left"
          :scrollable="true"
          :max-height="300"
          :close-on-click="false"
          class="dg-columns"
        >
          <b-button
            slot="trigger"
            icon-left="eye"
            size="is-small"
            icon-right="chevron-down"
          >
            columns
          </b-button>
          <draggable
            v-model="columns"
            group="columns"
            handle=".handle"
            @start="drag = true"
            @end="drag = false"
          >
            <b-dropdown-item
              v-for="(column, index) in columns"
              :key="index"
              aria-role="listitem"
            >
              <b-field>
                <b-checkbox
                  :id="column.field"
                  v-model="column.visible"
                  size="is-small"
                >
                  {{
                    column.label.startsWith('custom')
                      ? getCustomFieldLabel(column.label, 'Lead_Opportunity') : column.label
                  }}
                </b-checkbox>
                <i class="grip-vertical handle" />
              </b-field>
            </b-dropdown-item>
          </draggable>
          <hr class="dropdown-divider">
        </b-dropdown>
      </template>
    </table-header>

    <b-table
      ref="opportunitiesTable"
      v-click-outside-dg="{
        handler: 'onClickOutsideTable',
        exclude: [
          'inlineEditingSavePrompt',
          'oppClosingReasonModal',
          'saveEntryButton',
          'cancelEntryButton',
        ],
      }"
      :mobile-cards="false"
      :loading="loading || isLoading"
      :data="opportunities.content"
      :checkable="customizations.isCheckable"
      :checked-rows.sync="checkedRows"
      paginated
      backend-pagination
      :total="total"
      :per-page="perPage"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      detailed
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
      :narrowed="customizations.isNarrowed"
      :bordered="customizations.isBordered"
      :striped="customizations.isStriped"
      :sticky-header="customizations.isStickyHeaders"
      :scrollable="customizations.isScrollable"
      backend-filtering
      :debounce-search="800"
      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      @sort="onSort"
      @filters-change="onFilter"
      @page-change="onPageChange"
      @details-open="(row) => fetchActivities(row.id)"
      @update:selected="onClickOutsideEditing"
    >
      <b-table-column
        v-for="(column, index) in columns"
        :key="index"
        :label="column.label.startsWith('custom')
          ? getCustomFieldLabel(column.label, 'Lead_Opportunity') : column.label"
        :field="column.field"
        :searchable="column.searchable"
        :sortable="column.sortable"
        :visible="column.visible"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <template>
            <b-select
              v-show="column.searchField === 'stage'"
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option
                v-for="(stage, stageIndex) in opportunityStages"
                :key="stageIndex"
                :value="stage.value"
              >
                {{ stage.value }}
              </option>
            </b-select>

            <b-select
              v-show="column.searchField === 'types'"
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option
                v-for="(type, typeIndex) in opportunityTypes"
                :key="typeIndex"
                :value="type.value"
              >
                {{ type.value }}
              </option>
            </b-select>

            <b-select
              v-show="column.searchField === 'affiliations'"
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option :value="fullName">
                Direct Reports
              </option>

              <hr class="dropdown-divider">

              <option
                v-for="team in teams"
                :key="team.id"
                :value="team.name"
              >
                {{ team.name }}
              </option>

              <hr class="dropdown-divider">

              <option
                v-for="division in divisions"
                :key="division.id"
                :value="division.name"
              >
                {{ division.name }}
              </option>
            </b-select>

            <b-select
              v-show="column.searchField === 'temperature'"
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option
                v-for="(temperature, temperatureIndex) in temperatures"
                :key="temperatureIndex"
                :value="temperature.value"
              >
                {{ temperature.value }}
              </option>
            </b-select>

            <b-select
              v-show="column.searchField === 'probability'"
              v-model="props.filters[`${column.searchField}`]"
              size="is-small"
            >
              <option
                v-for="option in probabilities"
                :key="option.id"
                :value="option.value"
              >
                {{ option.value }}
              </option>
            </b-select>

            <b-input
              v-show="
                column.searchField === 'estimatedRevenue' ||
                  column.searchField === 'expectedGp'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
              type="number"
            />

            <b-input
              v-show="
                column.searchField === 'bidDueDate' ||
                  column.searchField === 'estInvoiceDate' ||
                  column.searchField === 'createdAt' ||
                  column.searchField === 'updatedAt' ||
                  column.searchField === 'estInstallDate' ||
                  column.searchField === 'estBookingDate'
              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
              type="date"
            />

            <b-input
              v-show="
                column.searchField === 'name' ||
                  column.searchField === 'accountName' ||
                  column.searchField === 'owners' ||
                  column.searchField === 'estimatedGrossProfitPercent' ||
                  column.searchField === 'contactName' ||
                  column.searchField === 'industry' ||
                  column.searchField === 'tags' ||
                  column.searchField === 'customField1' ||
                  column.searchField === 'customField2' ||
                  column.searchField === 'customField3' ||
                  column.searchField === 'customField4' ||
                  column.searchField === 'customField5' ||
                  column.searchField === 'customField6' ||
                  column.searchField === 'customField7' ||
                  column.searchField === 'customField8' ||
                  column.searchField === 'customField9' ||
                  column.searchField === 'customField10'

              "
              v-model="props.filters[`${column.searchField}`]"
              placeholder="Search..."
              icon="search"
              size="is-small"
            />
          </template>
        </template>

        <template v-slot="props">
          <template v-if="column.field === 'actions'">
            <div
              v-if="
                checkPermissions(props.row)
              "
              class="buttons"
            >
              <b-tooltip
                type="is-info"
                label="Add Activity"
                position="is-right"
              >
                <b-dropdown
                  aria-role="list"
                  append-to-body
                >
                  <b-button
                    slot="trigger"
                    type="is-text"
                    class="has-text-grey"
                    icon-right="plus"
                  />
                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 165)"
                  >
                    <b-icon icon="stopwatch" />
                    <span class="has-text-weight-semibold">Task</span>
                  </b-dropdown-item>

                  <hr class="dropdown-divider">

                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 164)"
                  >
                    <b-icon icon="sticky-note" />
                    <span class="has-text-weight-semibold">Note</span>
                  </b-dropdown-item>

                  <hr class="dropdown-divider">

                  <b-dropdown-item
                    aria-role="listitem"
                    class="has-text-grey"
                    @click="addActivity(props.row.id, props.row.name, 166)"
                  >
                    <b-icon icon="calendar" />
                    <span class="has-text-weight-semibold">Event</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Edit"
                position="is-right"
              >
                <b-button
                  tag="router-link"
                  :to="{
                    name: 'EditOpportunity',
                    params: { opportunityId: props.row.id },
                    query: props.row,
                  }"
                  type="is-text"
                  class="has-text-grey"
                  icon-right="edit"
                />
              </b-tooltip>

              <b-tooltip
                type="is-info"
                label="Email"
                position="is-right"
              >
                <b-button
                  type="is-text"
                  class="has-text-grey"
                  icon-right="envelope"
                  @click="
                    sendMail(props.row.contactEmail, props.row.contactName)
                  "
                />
              </b-tooltip>
            </div>
            <div v-else />
          </template>

          <template v-else-if="column.field === 'name'">
            <router-link
              class="has-text-weight-bold has-text-primary"
              :to="{
                name: 'ViewOpportunity',
                params: { opportunityId: props.row.id },
                query: props.row,
              }"
            >
              {{ props.row[column.field] }}
            </router-link>
          </template>

          <b-tooltip
            v-else
            label="Double-click to edit"
            class="is-info"
          >
            <template v-if="column.field === 'accountName'">
              <span
                v-if="props.row.accountName"
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row.accountName | capitalize }}
              </span>
            </template>

            <template v-else-if="column.field === 'contactName'">
              <span
                v-if="props.row.contactName"
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row.contactName | capitalize }}
              </span>
            </template>

            <template v-else-if="column.field === 'owners'">
              <template v-if="props.row[column.field]">
                <template
                  v-for="(owner, ownerIndex) in props.row.owners.split(',')"
                >
                  <template v-if="owner">
                    <b-taglist
                      :key="ownerIndex"
                      attached
                    >
                      <b-tag>
                        {{ owner.split("/")[0] }}
                      </b-tag>
                      <b-tag type="is-info">
                        {{ owner.split("/")[1] }} %
                      </b-tag>
                    </b-taglist>
                  </template>
                </template>
              </template>
            </template>

            <template v-else-if="column.field === 'affiliations'">
              <template v-if="props.row[column.field]">
                <template
                  v-for="(affiliation, affiliationIndex) in props.row.affiliations.split(',')"
                >
                  <template v-if="affiliation">
                    <b-taglist
                      :key="affiliationIndex"
                      attached
                    >
                      <b-tag>
                        {{ affiliation }}
                      </b-tag>
                    </b-taglist>
                  </template>
                </template>
              </template>
            </template>

            <template v-else-if="column.field === 'industry'">
              <template v-if="props.row.industry">
                {{ props.row.industry }}
              </template>
            </template>

            <template v-else-if="column.field === 'estimatedRevenue'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <currency-input
                  v-model="estimatedRevenue"
                  class="input is-small"
                  @input="setGrossProfit"
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                {{ props.row.estimatedRevenue | currency }}
              </span>
            </template>

            <template v-else-if="column.field === 'expectedGp'">
              <span v-if="isEditing && props.row.id === selectedOpportunityId">
                {{ estimatedGrossProfit | currency }}
              </span>

              <span v-else>
                {{ props.row.expectedGp | currency }}
              </span>
            </template>

            <template v-else-if="column.field === 'estimatedGrossProfitPercent'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-input
                  v-model="estGrossProfitPercentage"
                  size="is-small"
                  @input="setGrossProfit"
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template v-if="props.row.estimatedGrossProfitPercent">
                  {{ props.row.estimatedGrossProfitPercent }} %
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'probability'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-select
                  v-model="probability"
                  size="is-small"
                >
                  <option
                    v-for="option in probabilities"
                    :key="option.id"
                    :value="option.code"
                  >
                    {{ option.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row.probability"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ (props.row.probability / 100) | percent }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'bidDueDate'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-input
                  v-model="bidDueDate"
                  type="date"
                  size="is-small"
                  trap-focus
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template v-if="props.row.bidDueDate">
                  <b-tag
                    v-if="colorCoding.BIDDUEDATE"
                    :style="{
                      // eslint-disable-next-line max-len
                      borderColor: dateColor(
                        props.row.bidDueDate,
                        colorCoding.BIDDUEDATE.days,
                        'BIDDUEDATE'
                      ),
                    }"
                  >
                    {{ props.row.bidDueDate | date }}
                  </b-tag>
                  <b-tag
                    v-else
                    style="border-color: transparent"
                  >
                    {{ props.row.bidDueDate | date }}
                  </b-tag>
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'estBookingDate'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-input
                  v-model="estBookingDate"
                  type="date"
                  size="is-small"
                  trap-focus
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template
                  v-if="
                    props.row.estBookingDate &&
                      props.row.stage != 'closed_won' &&
                      props.row.stage != 'closed_lost'
                  "
                >
                  <b-tag
                    v-if="colorCoding.ESTBOOKINGDATE"
                    :style="{
                      // eslint-disable-next-line max-len
                      borderColor: dateColor(
                        props.row.estBookingDate,
                        colorCoding.ESTBOOKINGDATE.days,
                        'ESTBOOKINGDATE'
                      ),
                    }"
                  >
                    {{ props.row.estBookingDate | date }}
                  </b-tag>
                  <b-tag
                    v-else
                    style="border-color: transparent"
                  >
                    {{ props.row.estBookingDate | date }}
                  </b-tag>
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'estInstallDate'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-input
                  v-model="estInstallDate"
                  type="date"
                  size="is-small"
                  trap-focus
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template
                  v-if="
                    (props.row.estInstallDate &&
                      props.row.stage != 'closed_won') ||
                      props.row.stage != 'closed_lost'
                  "
                >
                  <b-tag
                    v-if="colorCoding.ESTINSTALLDATE"
                    :style="{
                      // eslint-disable-next-line max-len
                      borderColor: dateColor(
                        props.row.estInstallDate,
                        colorCoding.ESTINSTALLDATE.days,
                        'ESTINSTALLDATE'
                      ),
                    }"
                  >
                    {{ props.row.estInstallDate | date }}
                  </b-tag>
                  <b-tag
                    v-else
                    style="border-color: transparent"
                  >
                    {{ props.row.estInstallDate | date }}
                  </b-tag>
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'estInvoiceDate'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-input
                  v-model="estInvoiceDate"
                  type="date"
                  size="is-small"
                  trap-focus
                />
              </b-field>

              <span
                v-else
                class="is-pointer"
                @dblclick="onEdit(props.row, props)"
              >
                <template v-if="props.row.estInvoiceDate">
                  <b-tag
                    v-if="colorCoding.ESTINVOICEDATE"
                    :style="{
                      // eslint-disable-next-line max-len
                      borderColor: dateColor(
                        props.row.estInvoiceDate,
                        colorCoding.ESTINVOICEDATE.days,
                        'ESTINVOICEDATE'
                      ),
                    }"
                  >
                    {{ props.row.estInvoiceDate | date }}
                  </b-tag>
                  <b-tag
                    v-else
                    style="border-color: transparent"
                  >
                    {{ props.row.estInvoiceDate | date }}
                  </b-tag>
                </template>
              </span>
            </template>

            <template v-else-if="column.field === 'stage'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-select
                  v-model="stageId"
                  size="is-small"
                >
                  <option
                    v-for="(stage, stageIndex) in opportunityStages"
                    :key="stageIndex"
                    :value="stage.orderSequence"
                  >
                    {{ stage.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >{{ props.row[column.field] }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'types'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-taginput
                  v-model="typeId"
                  :data="filteredTags"
                  autocomplete
                  size="is-small"
                  field="value"
                  type="is-info"
                  :open-on-focus="true"
                  placeholder="Add a type"
                  @typing="getFilteredTags"
                />
              </b-field>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >{{ props.row[column.field] }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'temperature'">
              <b-field v-if="isEditing && props.row.id === selectedOpportunityId">
                <b-select
                  v-model="temperatureId"
                  size="is-small"
                >
                  <option
                    v-for="(temperature, temperatureIndex) in temperatures"
                    :key="temperatureIndex"
                    :value="temperature.id"
                  >
                    {{ temperature.value }}
                  </option>
                </b-select>
              </b-field>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  {{ props.row[column.field] }}
                </span>
              </template>
            </template>

            <template v-else-if="column.field === 'tags'">
              <template v-if="props.row.tags">
                <b-tag
                  v-for="(tag, tagIndex) in props.row.tags.split(',')"
                  :key="tagIndex"
                >
                  {{ tag }}
                </b-tag>
              </template>
            </template>

            <template
              v-else-if="
                column.field === 'createdAt' || column.field === 'updatedAt'
              "
            >
              <template v-if="props.row[column.field]">
                {{ props.row[column.field] | date }}
              </template>
            </template>

            <template v-else-if="column.field.startsWith('custom')">
              <div
                v-if="isEditing && props.row.id === selectedOpportunityId
                  && customFieldsList.length > 0"
              >
                <validation-provider
                  v-for="(field, fieldIndex) in customFieldsList.filter(
                    (item) => item.label === getCustomFieldLabel(column.label, 'Lead_Opportunity'))"
                  :key="fieldIndex"
                  v-slot="{ errors }"
                  :rules="{required: field.isRequired}"
                  :name="field.label"
                >
                  <b-field
                    v-if="field.type === 'TEXT_INPUT'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <b-input
                      v-model="props.row[column.field]"
                      size="is-small"
                      @input="setNewEditedValues(
                        $event, getCustomFieldLabel(column.label, 'Lead_Opportunity')
                      )"
                    />
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'DROP_DOWN'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <b-select
                      v-model="props.row[column.field]"
                      size="is-small"
                      expanded
                      @input="setNewEditedValues(
                        $event, getCustomFieldLabel(column.label, 'Lead_Opportunity')
                      )"
                    >
                      <option
                        v-for="(option, optionIndex) in field.lookups"
                        :key="optionIndex"
                        :value="option.value"
                      >
                        {{ option.value }}
                      </option>
                    </b-select>
                  <!--<div class="column">
                      <b-button
                        aria-label="Clear Value"
                        type="is-danger"
                        icon-right="close"
                        @click="clearValue(index)"
                      />
                    </div>-->
                  </b-field>
                  <b-field
                    v-else-if="field.type === 'MULTI_SELECT'"
                    :type="{ 'is-danger': errors[0] }"
                    :message="errors"
                    :class="{'is-required' : field.isRequired }"
                  >
                    <div class="columns">
                      <div class="column">
                        <b-taginput
                          v-model="props.row[column.field + 'Arr']"
                          v-multi-select-field="{
                            index: props.index,
                            field: column.field + 'Arr',
                            handler: 'setFormattedMultiSelectValues',
                          }"
                          :data="field.lookups.map(i => i.value)"
                          autocomplete
                          size="is-small"
                          field="value"
                          type="is-info"
                          :open-on-focus="true"
                          placeholder="Select one or multiple"
                          @typing="(val) => getCustomFieldFilteredTags(val, field)"
                          @input="setNewEditedValues(
                            $event, getCustomFieldLabel(column.label, 'Lead_Opportunity')
                          )"
                        />
                      </div>
                    </div>
                  </b-field>
                </validation-provider>
              </div>

              <template v-else>
                <span
                  v-if="props.row[column.field]"
                  class="is-pointer"
                  @dblclick="onEdit(props.row, props)"
                >
                  <template
                    v-if="
                      customFieldsList.filter((item
                      ) => item.label === getCustomFieldLabel(column.label, 'Lead_Opportunity')
                      )[0].type === 'CURRENCY'
                    "
                  >
                    {{ props.row[column.field] | currency }}
                  </template>
                  <template v-else>
                    {{ props.row[column.field] }}
                  </template>
                </span>
              </template>
            </template>

            <template v-else>
              <template v-if="props.row[column.field]">
                {{ props.row[column.field] }}
              </template>
            </template>
          </b-tooltip>
        </template>
      </b-table-column>

      <template
        slot="detail"
        slot-scope="props"
      >
        <activities-minimal-table />

        <b-button
          type="is-info"
          icon-left="bell"
          tag="router-link"
          :to="{
            name: 'ViewOpportunity',
            params: { opportunityId: props.row.id },
            query: { activeTab: 1 },
          }"
        >
          view all
        </b-button>
      </template>

      <template slot="footer">
        <table-footer
          :first-item="firstItem"
          :page="page"
          :per-page="perPage"
          :total="total"
        >
          <template #page-dropdown>
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-top-left"
            >
              <b-button
                slot="trigger"
                size="is-small"
                type="is-info"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(i, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="i"
                >
                  {{ i }}
                </b-dropdown-item>
                <hr
                  :key="i"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </template>
        </table-footer>
      </template>

      <template slot="empty">
        <empty-table table-name="opportunities" />
      </template>
    </b-table>

    <b-modal
      v-model="isComponentModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="re-assign owners modal"
      aria-modal
    >
      <template>
        <reassign-modal-form
          modal-title="RE-ASSIGN OWNER"
          :route-name="routeName"
          v-bind="formProps"
          @close="closeModal"
        />
      </template>
    </b-modal>

    <b-modal
      ref="oppClosingReasonModal"
      v-model="isOppClosingReasonModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="closing reason modal"
      aria-modal
    >
      <template #default="props">
        <opportunity-closing-reason-modal
          ref="closingReasonField"
          :opportunity-stages="opportunityStages"
          @close="props.close"
        />
      </template>
    </b-modal>

    <b-modal
      ref="inlineEditingSavePrompt"
      v-model="isSavePromptActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="inline editing save modal"
      aria-modal
    >
      <template #default="props">
        <inline-editing-save-prompt
          @save="updateOpportunity"
          @close="props.close"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';
import SearchMixin from '@/mixins/SearchMixin';

import debounce from '@/utils/debounce';

import defaultImage from '@/assets/images/avatar.svg';
import {
  TableFooter,
  TableHeader,
  EmptyTable,
  ReassignModalForm,
  OpportunityClosingReasonModal,
  InlineEditingSavePrompt,
} from '@/components/Shared';
import { ActivitiesMinimalTable } from '@/components/Activities';

export default {
  name: 'OpportunitiesTable',

  components: {
    TableFooter,
    TableHeader,
    EmptyTable,
    ActivitiesMinimalTable,
    draggable,
    ReassignModalForm,
    ValidationProvider,
    OpportunityClosingReasonModal,
    InlineEditingSavePrompt,
  },

  mixins: [SearchMixin],

  getSubordinates: (state) => {
    const subordinates = [...state.subordinates];
    const userId = localStorage.getItem('user_id');
    // eslint-disable-next-line eqeqeq
    const index = subordinates.findIndex((subordinate) => subordinate.id == userId);
    subordinates.splice(index, 1);
    subordinates.sort((a, b) => a.fullname.localeCompare(b.fullname));
    return subordinates;
  },

  data: () => ({
    checkedRows: [],
    rowInEdit: {},
    editingIndex: null,

    isLoading: true,

    fullName: localStorage.getItem('full_name'),

    total: 0,
    page: 1,
    perPage: 50,
    pages: [10, 25, 50, 100, 250, 500, 1000, 2000],

    defaultOpenedDetails: [],

    sortField: 'updatedAt',
    sortOrder: 'desc',
    defaultSortOrder: 'desc',
    sortIcon: 'menu-up',
    sortIconSize: 'is-small',

    defaultImage,

    isEditing: false,
    inEditingMode: false,
    selectedOpportunityId: null,

    isSavePromptActive: false,

    savePromptOpen: false,

    openSwitch: true,

    filters: {},

    exportFields: {},

    searchClause: '',

    isComponentModalActive: false,

    isOppClosingReasonModal: false,

    formProps: {
      dataIDList: [],
    },

    columns: [
      {
        id: 1,
        label: 'Opportunity Name',
        field: 'name',
        sortable: true,
        searchable: true,
        searchField: 'name',
        exportField: 'name',
        sticky: false,
        visible: true,
      },
      {
        id: 2,
        label: 'Account',
        field: 'accountName',
        sortable: true,
        searchable: true,
        searchField: 'accountName',
        exportField: 'accountName',
        sticky: false,
        visible: true,
      },
      {
        id: 3,
        label: 'Owners',
        field: 'owners',
        sortable: true,
        searchable: true,
        searchField: 'owners',
        exportField: 'exportOwners',
        sticky: false,
        visible: true,
      },
      {
        id: 4,
        label: 'Stage',
        field: 'stage',
        sortable: true,
        searchable: true,
        searchField: 'stage',
        exportField: 'stage',
        sticky: false,
        visible: true,
      },
      {
        id: 5,
        label: 'Opportunity Type',
        field: 'types',
        sortable: true,
        searchable: true,
        searchField: 'types',
        exportField: 'types',
        sticky: false,
        visible: true,
      },
      {
        id: 6,
        label: 'Est Booking Date',
        field: 'estBookingDate',
        sortable: true,
        searchable: true,
        searchField: 'estBookingDate',
        exportField: 'estBookingDate',
        sticky: false,
        visible: true,
      },
      {
        id: 7,
        label: 'Probability',
        field: 'probability',
        sortable: true,
        searchable: true,
        searchField: 'probability',
        exportField: 'probability',
        sticky: false,
        visible: true,
      },
      {
        id: 8,
        label: 'Expected Revenue',
        field: 'estimatedRevenue',
        sortable: true,
        searchable: true,
        searchField: 'estimatedRevenue',
        exportField: 'estimatedRevenue',
        sticky: false,
        visible: true,
      },
      {
        id: 9,
        label: 'Estimated Gross Profit %',
        field: 'estimatedGrossProfitPercent',
        sortable: true,
        searchable: true,
        searchField: 'estimatedGrossProfitPercent',
        exportField: 'estimatedGrossProfitPercent',
        sticky: false,
        visible: true,
      },
      {
        id: 10,
        label: 'Estimated Gross Profit',
        field: 'expectedGp',
        sortable: true,
        searchable: true,
        searchField: 'expectedGp',
        exportField: 'expectedGp',
        sticky: false,
        visible: false,
      },
      {
        id: 11,
        label: 'Contact',
        field: 'contactName',
        sortable: true,
        searchable: true,
        searchField: 'contactName',
        exportField: 'contactName',
        sticky: false,
        visible: false,
      },
      {
        id: 12,
        label: 'Industry',
        field: 'industry',
        sortable: true,
        searchable: true,
        searchField: 'industry',
        exportField: 'industry',
        sticky: false,
        visible: false,
      },
      {
        id: 13,
        label: 'Temperature',
        field: 'temperature',
        sortable: true,
        searchable: true,
        searchField: 'temperature',
        exportField: 'temperature',
        sticky: false,
        visible: false,
      },
      {
        id: 14,
        label: 'Bid Due Date',
        field: 'bidDueDate',
        sortable: true,
        searchable: true,
        searchField: 'bidDueDate',
        exportField: 'bidDueDate',
        sticky: false,
        visible: false,
      },
      {
        id: 15,
        label: 'Est Install Date',
        field: 'estInstallDate',
        sortable: true,
        searchable: true,
        searchField: 'estInstallDate',
        exportField: 'estInstallDate',
        sticky: false,
        visible: false,
      },
      {
        id: 16,
        label: 'Est Invoice Date',
        field: 'estInvoiceDate',
        sortable: true,
        searchable: true,
        searchField: 'estInvoiceDate',
        exportField: 'estInvoiceDate',
        sticky: false,
        visible: false,
      },
      {
        id: 17,
        label: 'Tags',
        field: 'tags',
        sortable: false,
        searchable: true,
        searchField: 'tags',
        exportField: 'tags',
        sticky: false,
        visible: false,
      },
      {
        id: 18,
        label: 'Created On',
        field: 'createdAt',
        sortable: true,
        searchable: true,
        searchField: 'createdAt',
        exportField: 'createdAt',
        sticky: false,
        visible: false,
      },
      {
        id: 19,
        label: 'Modified On',
        field: 'updatedAt',
        sortable: true,
        searchable: true,
        searchField: 'updatedAt',
        exportField: 'updatedAt',
        sticky: false,
        visible: false,
      },
      {
        id: '20',
        label: 'Actions',
        field: 'actions',
        sortable: false,
        searchable: false,
        searchField: '',
        exportField: '',
        sticky: false,
        visible: false,
      },
      {
        id: '21',
        label: 'custom_field_1',
        field: 'customField1',
        sortable: true,
        searchable: true,
        searchField: 'customField1',
        exportField: 'customField1',
        sticky: false,
        visible: false,
      },
      {
        id: '22',
        label: 'custom_field_2',
        field: 'customField2',
        sortable: true,
        searchable: true,
        searchField: 'customField2',
        exportField: 'customField2',
        sticky: false,
        visible: false,
      },
      {
        id: '23',
        label: 'custom_field_3',
        field: 'customField3',
        sortable: true,
        searchable: true,
        searchField: 'customField3',
        exportField: 'customField3',
        sticky: false,
        visible: false,
      },
      {
        id: '24',
        label: 'custom_field_4',
        field: 'customField4',
        sortable: true,
        searchable: true,
        searchField: 'customField4',
        exportField: 'customField4',
        sticky: false,
        visible: false,
      },
      {
        id: '25',
        label: 'custom_field_5',
        field: 'customField5',
        sortable: true,
        searchable: true,
        searchField: 'customField5',
        exportField: 'customField5',
        sticky: false,
        visible: false,
      },
      {
        id: '26',
        label: 'custom_field_6',
        field: 'customField6',
        sortable: true,
        searchable: true,
        searchField: 'customField6',
        exportField: 'customField6',
        sticky: false,
        visible: false,
      },
      {
        id: '27',
        label: 'custom_field_7',
        field: 'customField7',
        sortable: true,
        searchable: true,
        searchField: 'customField7',
        exportField: 'customField7',
        sticky: false,
        visible: false,
      },
      {
        id: '28',
        label: 'custom_field_8',
        field: 'customField8',
        sortable: true,
        searchable: true,
        searchField: 'customField8',
        exportField: 'customField8',
        sticky: false,
        visible: false,
      },
      {
        id: '29',
        label: 'custom_field_9',
        field: 'customField9',
        sortable: true,
        searchable: true,
        searchField: 'customField9',
        exportField: 'customField9',
        sticky: false,
        visible: false,
      },
      {
        id: '30',
        label: 'custom_field_10',
        field: 'customField10',
        sortable: true,
        searchable: true,
        searchField: 'customField10',
        exportField: 'customField10',
        sticky: false,
        visible: false,
      },
      {
        id: '31',
        label: 'Affiliations',
        field: 'affiliations',
        sortable: true,
        searchable: true,
        searchField: 'affiliations',
        exportField: 'affiliations',
        sticky: false,
        visible: false,
      },
    ],

    customizations: {
      isBordered: false,
      isCheckable: false,
      isNarrowed: false,
      isStickyHeaders: false,
      isScrollable: true,
      isStriped: true,
    },

    colorCoding: {
      ESTBOOKINGDATE: {
        dateField: 'ESTBOOKINGDATE',
        currentDateColor: '#ff6600',
        withinDateColor: '#fbff00',
        pastDateColor: '#d22222',
        days: 3,
      },
    },
  }),

  computed: {
    ...mapGetters({
      grid: 'Grids/getGrid',
      loading: 'DataGrids/getLoading',
      dateColorCoding: 'Lookups/getDateColorConfigs',
      opportunity: 'Opportunities/getOpportunity',
      opportunityStages: 'Lookups/getOpportunityStages',
      probabilities: 'Lookups/getProbabilities',
      opportunityTypes: 'Lookups/getOpportunityTypes',
      temperatures: 'Lookups/getTemperatures',
      opportunityReasonStages: 'Opportunities/getOpportunityReasonStages',
      opportunityClosingReasons: 'Opportunities/getOpportunityStageReasons',
      isWidgetsOpen: 'Settings/getIsWidgetsOpen',
      teams: 'Graphs/getTeams',
      divisions: 'Graphs/getDivisions',
    }),

    firstItem() {
      const firstItem = this.page * this.perPage - this.perPage + 1;
      return firstItem >= 0 ? firstItem : 0;
    },

    filteredTags() {
      return [...this.opportunityTypes];
    },

    opportunities: {
      get() {
        return this.$store.state.DataGrids.opportunities;
      },

      set(value) {
        return this.$store.commit('DataGrids/SET_OPPORTUNITIES_CONTENT', value);
      },
    },

    account: {
      get() {
        return this.$store.state.Opportunities.opportunity.account;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_ACCOUNT',
          value,
        );
      },
    },

    primaryContact: {
      get() {
        return this.$store.state.Opportunities.opportunity.primaryContact;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT',
          value,
        );
      },
    },

    probability: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .probability;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_PROBABILITY',
          value,
        );
      },
    },

    estimatedRevenue: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .estimatedRevenue;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_ESTIMATED_REVENUE',
          value,
        );
      },
    },

    estGrossProfitPercentage: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .estGrossProfitPercentage;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_EST_GROSS_PROFIT_PERCENTAGE',
          value,
        );
      },
    },

    estimatedGrossProfit: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .estimatedGrossProfit;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_ESTIMATED_GROSS_PROFIT',
          value,
        );
      },
    },

    temperatureId: {
      get() {
        return this.$store.state.Opportunities.opportunity.temperatureId;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_TEMPERATURE_ID',
          value,
        );
      },
    },

    stageId: {
      get() {
        return this.$store.state.Opportunities.opportunity.stageId;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_STAGE_ID',
          value,
        );
      },
    },

    typeId: {
      get() {
        const newArr = this.$store.state.Opportunities.opportunity.typeId;
        return this.opportunityTypes.filter((i) => {
          if (newArr) {
            const arr = newArr.filter((o) => o === i.id);
            if (arr.length > 0) {
              return i.id === arr[0];
            }
            return false;
          }
          return false;
        });
      },

      set(value) {
        const newArr = value.map((i) => i.id);
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_TYPE_ID', newArr);
      },
    },

    bidDueDate: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .bidDueDate;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_BID_DUE_DATE',
          value,
        );
      },
    },

    estBookingDate: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .estBookingDate;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_EST_BOOKING_DATE',
          value,
        );
      },
    },

    estInstallDate: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .estInstallDate;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_EST_INSTALL_DATE',
          value,
        );
      },
    },

    estInvoiceDate: {
      get() {
        return this.$store.state.Opportunities.opportunity.estimatedPotentials
          .estInvoiceDate;
      },

      set(value) {
        return this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_EST_INVOICE_DATE',
          value,
        );
      },
    },

    customFieldsList: {
      get() {
        return this.$store.state.Settings.customFields.leadOppCustomFields;
      },

      set(value) {
        return this.$store.commit('Settings/SET_LEAD_OPP_CUSTOM_FIELDS', value);
      },
    },

    customFields: {
      get() {
        return this.$store.state.Opportunities.opportunity.customFields;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_CUSTOM_FIELDS', value);
      },
    },

    closingReason: {
      get() {
        return this.$store.state.Opportunities.opportunity.reasonId;
      },

      set(value) {
        return this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', value);
      },
    },

    required() {
      if (!this.opportunityStages || !this.stageId) return false;

      const matchingStages = this.opportunityStages.filter((i) => i.orderSequence === this.stageId);
      if (matchingStages.length === 0) return false;

      const { code } = matchingStages[0];

      if (code && code.includes('closed') && this.opportunityReasonStages.length > 0) {
        const result = this.opportunityReasonStages.filter((i) => i.stageCode === code);
        return result.length > 0 ? result[0].isRequired : false;
      }

      return false;
    },

    // Check if a user is an admin
    isAdmin() {
      return this.isUserAdmin();
    },

    // Check if a user is a sales manager
    isSalesManager() {
      return this.isUserSalesManager();
    },

    routeName() {
      return this.$route && this.$route.name ? this.$route.name : '';
    },
  },

  watch: {
    dateColorCoding: {
      handler(value) {
        if (value) {
          this.colorCoding = value;
        }
      },

      deep: true,
      immediate: true,
    },

    rowInEdit: {
      handler(value) {
        this.$store.commit('Opportunities/SET_OPPORTUNITY_OBJECT', value);
      },
    },

    stageId: {
      async handler(value) {
        if (this.opportunityStages && this.opportunityStages.length === 0) {
          return;
        }

        if (!this.opportunityStages.filter((i) => i.orderSequence === value)[0]) {
          return;
        }

        const { code } = this.opportunityStages.filter((i) => i.orderSequence === value)[0];
        if (this.isEditing && code && code.includes('closed')) {
          this.$store.commit('Opportunities/CLEAR_OPPORTUNITY_STAGE_REASONS');
          if (this.opportunityReasonStages.length > 0) {
            const stages = this.opportunityReasonStages.filter((i) => i.stageCode === code);
            if (stages.length > 0) {
              const { id } = stages[0];
              this.isLoading = true;
              await this.$store.dispatch('Opportunities/fetchOpportunityStageReasons', id);
              this.isLoading = false;
            }
          }
          if (this.opportunityClosingReasons.length > 0) {
            this.isOppClosingReasonModal = true;
            this.$store.commit('Opportunities/SET_OPPORTUNITY_CLOSING_REASON', null);
            this.$refs.closingReasonField.resetValidator();
          }
        }
      },
    },

    perPage: {
      handler() {
        this.fetchOpportunities();
      },
      deep: true,
    },

    columns: {
      handler(value) {
        this.generateExportFields(value);
      },

      deep: true,
      immediate: true,
    },

    selectedAccount(value) {
      if (value.accountId) {
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_ACCOUNT_ID',
          value.accountId,
        );
      }
    },

    selectedContact(value) {
      if (value.contactId) {
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID',
          value.contactId,
        );
      }
    },

    openSwitch: {
      handler() {
        this.fetchOpportunities();
      },

      deep: true,
    },

    $route: {
      handler() {
        // this.filters = {};
        this.fetchOpportunities();
      },
      deep: true,
    },
  },

  async created() {
    try {
      await this.$store.dispatch('Settings/fetchCustomFields', 'Lead_Opportunity');
      const organizationId = localStorage.getItem('organization_id');
      const response = await this.$store.dispatch('Grids/fetchGrid', {
        gridName: 'opportunities',
        organizationId,
      });

      const sortedColumnList = [...this.columns]
        .map((newItem) => {
          if (newItem.label.startsWith('custom')) {
            return ({ ...newItem, visible: true });
          }
          return newItem;
        })
        .filter((item) => (item.label.startsWith('custom')
          ? this.getCustomFieldLabel(item.label, 'Lead_Opportunity') !== ''
          : true));
      this.columns = sortedColumnList;

      if (response) {
        const isConfigurationValid = this.configurationValidator();
        if (isConfigurationValid) {
          this.setGrid();
        }
      } else {
        await this.$store.dispatch('Grids/createGrid', {
          organizationid: localStorage.getItem('organization_id'),
          userid: localStorage.getItem('user_id'),
          columns: this.columns,
          name: 'opportunities',
          customizations: { ...this.customizations, isWidgetsOpen: this.isWidgetsOpen },
          filters: this.filters,
        });
      }

      this.openSwitch = this.grid.filters.openStage;

      await this.mapSavedFilters();
      const colorResponse = await this.$store.dispatch(
        'Lookups/fetchDateColorConfig',
        {
          dateField: 'ESTBOOKINGDATE',
        },
      );
      if (colorResponse) {
        this.colorCoding = this.dateColorCoding;
      } else {
        await this.$store.dispatch(
          'Lookups/addDateColorConfig',
          this.colorCoding.ESTBOOKINGDATE,
        );
      }
    } catch (error) {
      console.error(error);
    }
  },

  mounted() {
    try {
      this.isLoading = true;
      if (Object.keys(this.$route.query).length > 0 && this.$route.path.includes('/opportunities')) {
        const payload = { ...this.$route.query };
        delete payload?.activeTab;
        this.filters = payload;
      }
      if (this.grid.columns && (!this.grid.filters
        || Object.keys(this.grid.filters).length === 0)) {
        this.fetchOpportunities();
      }

      this.$store.dispatch('Lookups/fetchTemperatures');
      this.$store.dispatch('Lookups/fetchProbabilities');
      this.$store.dispatch('Lookups/fetchOpportunityTypes');
      this.$store.dispatch('Lookups/fetchDateColorConfig', {
        dateField: 'ESTBOOKINGDATE',
      });
      this.$store.dispatch('Lookups/fetchDateColorConfig', {
        dateField: 'BIDDUEDATE',
      });
      this.$store.dispatch('Lookups/fetchDateColorConfig', {
        dateField: 'ESTINSTALLDATE',
      });
      this.$store.dispatch('Lookups/fetchDateColorConfig', {
        dateField: 'ESTINVOICEDATE',
      });
      this.$store.dispatch('Opportunities/fetchOpportunityReasonStages');
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  beforeUpdate() {
    if (
      localStorage.getItem('access_token')
      && Object.keys(this.grid).length > 0
      && this.$route.path.includes('/opportunities')
    ) {
      this.$store.dispatch('Grids/updateGrid', {
        organizationid: localStorage.getItem('organization_id'),
        userid: localStorage.getItem('user_id'),
        columns: this.columns,
        name: 'opportunities',
        customizations: { ...this.customizations, isWidgetsOpen: this.isWidgetsOpen },
        filters: { ...this.filters, openStage: this.openSwitch },
      });
    }
  },

  methods: {
    checkPermissions(row) {
      return this.hasRole('ROLE_BUSINESS_ADMIN')
        || this.isOwner(row.ownerIds.split(',')[0] || 0, row.ownerIds.split(',')[1] || 0)
        || this.isManager(row.ownerIds.split(',')[0] || 0, row.ownerIds.split(',')[1] || 0)
        || this.isTeammate(row.teamIds);
    },

    mapSavedFilters() {
      if (this.grid.filters) {
        this.$refs.opportunitiesTable.filters = { ...this.grid.filters };
      }
      return '';
    },

    onClickOutsideEditing(row) {
      if (row.id && this.isEditing) {
        if (row.id !== this.selectedOpportunityId) {
          this.savePromptOpen = true;
          this.isSavePromptActive = true;
        }
      }
    },

    onClickOutsideTable() {
      if (this.isEditing) {
        this.savePromptOpen = true;
        this.isSavePromptActive = true;
      }
    },

    getFilteredTags(text) {
      this.filteredTags = this.opportunityTypes.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    setMultiSelectFields() {
      if (this.selectedOpportunityId && this.isEditing) {
        this.rowInEdit = {
          ...this.opportunity,
          customFields: [...this.customFields].map((it) => ({
            ...it,
            value: Array.isArray(it.value)
              ? it.value.join(',') : it.value,
          })),
        };
      }
    },

    async setFormattedMultiSelectValues(index, field) {
      try {
        if (this.selectedOpportunityId && this.isEditing) {
          this.opportunities = this.opportunities.content.map((item, ind) => {
            if (index === ind && this.inEditingMode) {
              const newItem = { ...item };
              if (item[field]) {
                newItem[field] = item[field] && Array.isArray(item[field]) ? item[field] : item[field].split(' ');
              }
              return newItem;
            }
            return item;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async setNewEditedValues(event, label) {
      if (this.selectedOpportunityId && this.isEditing) {
        const customFields = [...this.customFields];
        const findField = customFields.findIndex((obj) => obj.label === label);
        const newObj = customFields[findField];
        customFields.splice(findField, 1, {
          ...newObj,
          value: Array.isArray(event)
            ? event.join(',') : event,
        });

        this.rowInEdit = {
          ...this.opportunity,
          customFields,
        };
      }
    },

    getCustomFieldFilteredTags(text, field) {
      this.filteredTags = field.lookups.filter((option) => option.value
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0);
    },

    generateExportFields(data) {
      const result = data
        .filter((el) => el.visible && el.exportField)
        .map((el) => [el.label.startsWith('custom')
          ? this.getCustomFieldLabel(el.label, 'Lead_Opportunity') : el.label, el.exportField]);
      this.exportFields = Object.fromEntries(new Map(result));
    },

    today(date) {
      const day = new Date(date).setHours(0, 0, 0, 0);
      const now = new Date().setHours(0, 0, 0, 0);
      if (day === now) {
        return true;
      }
      return false;
    },

    pastDate(date) {
      const day = new Date(date).setHours(0, 0, 0, 0);
      const now = new Date().setHours(0, 0, 0, 0);
      if (day < now) {
        return true;
      }
      return false;
    },

    inFutureDays(date, days) {
      if (days === 0) {
        return false;
      }
      const day = new Date(date).setHours(0, 0, 0, 0);
      const now = new Date();
      // eslint-disable-next-line max-len
      const futureDate = new Date(
        now.getTime() + days * 24 * 60 * 60 * 1000,
      ).setHours(0, 0, 0, 0);
      if (now.getTime() < day && day <= futureDate) {
        return true;
      }
      return false;
    },

    dateColor(date, futureDays, dateType) {
      let result = '';

      if (this.today(date)) {
        result = this.colorCoding[dateType].currentDateColor;
      } else if (this.pastDate(date)) {
        result = this.colorCoding[dateType].pastDateColor;
      } else if (this.inFutureDays(date, futureDays)) {
        result = this.colorCoding[dateType].withinDateColor;
      } else {
        result = 'white';
      }

      return result;
    },

    configurationValidator() {
      const defaultConfiguration = this.columns.map((el) => el.label).sort();
      const userConfiguration = this.grid.columns.map((el) => el.label).sort();
      const result = defaultConfiguration.every(
        (configuration, index) => configuration === userConfiguration[index],
      );
      return result;
    },

    /**
     * setGrossProfit
     *
     */
    setGrossProfit() {
      if (this.estimatedRevenue > 0) {
        const estimatedGrossProfit = (this.estimatedRevenue / 100) * this.estGrossProfitPercentage;
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_ESTIMATED_GROSS_PROFIT',
          estimatedGrossProfit,
        );
      }
    },

    /**
     * setGrossProfitPercentage
     *
     */
    setGrossProfitPercentage() {
      if (this.estimatedRevenue > 0) {
        const estGrossProfitPerc = (this.estimatedGrossProfit / this.estimatedRevenue) * 100;
        this.$store.commit(
          'Opportunities/SET_OPPORTUNITY_EST_GROSS_PROFIT_PERCENTAGE',
          estGrossProfitPerc,
        );
      }
    },

    /**
     * setGrid
     *
     */
    setGrid() {
      this.columns.sort(
        (a, b) => this.grid.columns.findIndex((el) => el.label === a.label)
          - this.grid.columns.findIndex((el) => el.label === b.label),
      );

      const getVisibility = (fieldName) => {
        const column = this.grid.columns.find((el) => el.label === fieldName);
        if (column) {
          return column.visible;
        }
        return true;
      };

      const newColumns = this.columns.map((el) => ({
        ...el,
        visible: getVisibility(el.label),
      }));

      const customFieldExists = (fieldName) => {
        const data = JSON.parse(localStorage.getItem('custom_fields'));
        const customFields = [...data];
        return customFields.includes((el) => el.code === fieldName && (el.objectName === 'Lead_Opportunity'));
      };

      const hasCustomField = (fieldName) => newColumns.includes(
        (el) => el.label === fieldName,
      );

      // check for customFields available & append
      if (!hasCustomField('custom_field_1') && customFieldExists('custom_field_1')) {
        newColumns.push(
          {
            id: '21',
            label: 'custom_field_1',
            field: 'customField1',
            sortable: true,
            searchable: true,
            searchField: 'customField1',
            exportField: 'customField1',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_2') && customFieldExists('custom_field_2')) {
        newColumns.push(
          {
            id: '22',
            label: 'custom_field_2',
            field: 'customField2',
            sortable: true,
            searchable: true,
            searchField: 'customField2',
            exportField: 'customField2',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_3') && customFieldExists('custom_field_3')) {
        newColumns.push(
          {
            id: '23',
            label: 'custom_field_3',
            field: 'customField3',
            sortable: true,
            searchable: true,
            searchField: 'customField3',
            exportField: 'customField3',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_4') && customFieldExists('custom_field_4')) {
        newColumns.push(
          {
            id: '24',
            label: 'custom_field_4',
            field: 'customField4',
            sortable: true,
            searchable: true,
            searchField: 'customField4',
            exportField: 'customField4',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_5') && customFieldExists('custom_field_5')) {
        newColumns.push(
          {
            id: '25',
            label: 'custom_field_5',
            field: 'customField5',
            sortable: true,
            searchable: true,
            searchField: 'customField5',
            exportField: 'customField5',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_6') && customFieldExists('custom_field_6')) {
        newColumns.push(
          {
            id: '26',
            label: 'custom_field_6',
            field: 'customField6',
            sortable: true,
            searchable: true,
            searchField: 'customField6',
            exportField: 'customField6',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_7') && customFieldExists('custom_field_7')) {
        newColumns.push(
          {
            id: '27',
            label: 'custom_field_7',
            field: 'customField7',
            sortable: true,
            searchable: true,
            searchField: 'customField7',
            exportField: 'customField7',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_8') && customFieldExists('custom_field_8')) {
        newColumns.push(
          {
            id: '28',
            label: 'custom_field_8',
            field: 'customField8',
            sortable: true,
            searchable: true,
            searchField: 'customField8',
            exportField: 'customField8',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_9') && customFieldExists('custom_field_9')) {
        newColumns.push(
          {
            id: '29',
            label: 'custom_field_9',
            field: 'customField9',
            sortable: true,
            searchable: true,
            searchField: 'customField9',
            exportField: 'customField9',
            sticky: false,
            visible: true,
          },
        );
      } else if (!hasCustomField('custom_field_10') && customFieldExists('custom_field_10')) {
        newColumns.push(
          {
            id: '30',
            label: 'custom_field_10',
            field: 'customField10',
            sortable: true,
            searchable: true,
            searchField: 'customField10',
            exportField: 'customField10',
            sticky: false,
            visible: true,
          },
        );
      } else if (hasCustomField('custom_field_1') && !customFieldExists('custom_field_1')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_1');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_2') && !customFieldExists('custom_field_2')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_2');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_3') && !customFieldExists('custom_field_3')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_3');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_4') && !customFieldExists('custom_field_4')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_4');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_5') && !customFieldExists('custom_field_5')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_5');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_6') && !customFieldExists('custom_field_6')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_6');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_7') && !customFieldExists('custom_field_7')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_7');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_8') && !customFieldExists('custom_field_8')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_8');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_9') && !customFieldExists('custom_field_9')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_9');
        newColumns.splice(index, 1);
      } else if (hasCustomField('custom_field_10') && !customFieldExists('custom_field_10')) {
        const index = newColumns.findIndex((el) => el.label === 'custom_field_10');
        newColumns.splice(index, 1);
      }
      this.columns = newColumns;
      this.customizations = this.grid.customizations;
      this.filters = this.grid.filters || {};
    },
    /**
     * sendMail
     *
     * @param {String} email
     * @param {String} subject
     */
    sendMail(email, subject) {
      window.location.assign(`mailto:${email}?subject=${subject}`);
    },

    /**
     * addActivity
     *
     * @param {Number} opportunityId
     * @param {String} opportunity
     * @param {Number} typeId
     *
     */
    addActivity(opportunityId, opportunity, typeId) {
      this.$router.push(
        `/activities/add?opportunityId=${opportunityId}&opportunity=${opportunity}&typeId=${typeId}`,
      );
      this.$store.commit('Activities/SET_ACTIVITY_ACTIVITY_TYPE_ID', typeId);
    },

    /**
     * fetchActivities
     *
     * @param {Number} value - Opportunity ID
     */
    fetchActivities(value) {
      this.defaultOpenedDetails = [value];
      this.$store.dispatch('Activities/fetchEmbeddedOpportunityActivities', {
        opportunityId: value,
        page: 0,
        perPage: 5,
      });
    },

    /**
     * fetchOpportunities
     *
     */
    async fetchOpportunities() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach(
          (key) => filters[key] === '' && delete filters[key],
        );
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt)
            .toISOString()
            .substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt)
            .toISOString()
            .substring(0, 10);
        }
      }

      if (this.openSwitch) {
        filters = { ...filters, openStage: this.openSwitch };
      }

      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, primaryContactId: this.$route.params.contactId };
      }
      if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }
      try {
        await this.$store.dispatch('DataGrids/fetchOpportunities', {
          page: this.page - 1,
          size: this.perPage,
          sort: [this.sortField, this.sortOrder],
          filters:
            filters && Object.keys(filters).length > 0
              ? `&${new URLSearchParams(filters).toString()}`
              : '',
        });
        this.total = this.opportunities.totalElements;
      } catch (error) {
        console.error(error);
      }
    },

    async exportOpportunities() {
      let filters;
      if (typeof this.filters !== 'object') {
        filters = '';
      } else {
        filters = { ...this.filters };
        Object.keys(filters).forEach(
          (key) => filters[key] === '' && delete filters[key],
        );
        if (Object.keys(filters).includes('createdAt')) {
          filters.createdAt = new Date(filters.createdAt).setDate(
            new Date(filters.createdAt).getDate(),
          );
          filters.createdAt = new Date(filters.createdAt)
            .toISOString()
            .substring(0, 10);
        }
        if (Object.keys(filters).includes('updatedAt')) {
          filters.updatedAt = new Date(filters.updatedAt).setDate(
            new Date(filters.updatedAt).getDate(),
          );
          filters.updatedAt = new Date(filters.updatedAt)
            .toISOString()
            .substring(0, 10);
        }
      }

      if (this.openSwitch) {
        filters = { ...filters, openStage: this.openSwitch };
      }
      if (this.$route.name === 'ViewContact') {
        filters = { ...filters, primaryContactId: this.$route.params.contactId };
      }
      if (this.$route.name === 'ViewAccount') {
        filters = { ...filters, accountId: this.$route.params.accountId };
      }

      try {
        await this.$store.dispatch('Exports/fetchOpportunities', {
          sort: [this.sortField, this.sortOrder],
          filters:
            filters && Object.keys(filters).length > 0
              ? `&${new URLSearchParams(filters).toString()}`
              : '',
        });
      } catch (error) {
        console.error(error);
      }
    },

    /**
     * onPageChange
     *
     * @param {Number} page
     */
    onPageChange(page) {
      this.page = page;
      this.fetchOpportunities();
    },

    /**
     * onSort
     *
     * @param {String} field
     * @param {String} order
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.fetchOpportunities();
    },

    /**
     * onFilter
     *
     * @param {object} filters
     */
    onFilter(filters) {
      this.filters = filters;
      this.fetchOpportunities();
    },

    /**
     * resetFilters
     *
     */
    resetFilters() {
      this.$refs.opportunitiesTable.resetMultiSorting();
      this.$refs.opportunitiesTable.filters = {};
      this.searchClause = '';
      this.filters = {};
      this.fetchOpportunities();
    },

    /**
     * onEdit
     *
     * @param {object} opportunity
     */
    async onEdit(currentOpportunity, props) {
      if (this.checkPermissions(currentOpportunity)) {
        try {
          this.$store.commit('Opportunities/SET_UPDATE_OPPORTUNITY', currentOpportunity);
          this.selectedOpportunityId = currentOpportunity.id;
          this.isEditing = true;

          this.rowInEdit = {
            ...this.opportunity,
            customFields: this.customFieldsList.map((item) => {
              const sortedArr = this.opportunity.customFields
                .filter((newItem) => newItem.customFieldId === item.id);
              if (sortedArr.length > 0) {
                return (
                  {
                    customFieldId: item.id,
                    customFieldEntryId: sortedArr.length > 0
                      ? sortedArr[0].customFieldEntryId : null,
                    label: item.label,
                    value: sortedArr[0].value && sortedArr[0].value.split(',').length > 1
                      ? sortedArr[0].value.split(',') : sortedArr[0].value,
                  }
                );
              }
              return (
                {
                  customFieldId: item.id,
                  customFieldEntryId: null,
                  label: item.label,
                  value: null,
                }
              );
            }),
          };

          this.opportunities = this.opportunities.content.map((item, ind) => {
            if (props.index === ind) {
              const entries = Object.entries(item);
              entries.forEach((i) => {
                if (i[0].startsWith('customField') && i[1] !== null && i[0].endsWith('Arr') === false) {
                  entries.push([`${i[0]}Arr`, i[1].split(',').length > 1 ? i[1].split(',') : i[1]]);
                }
              });

              return {
                ...Object.fromEntries(entries),
              };
            }
            return item;
          });

          this.editingIndex = props.index;

          this.setMultiSelectFields();
        } catch (error) {
          console.error(error);
        } finally {
          this.inEditingMode = true;
          this.$emit('finished-mapping');
        }
      } else {
        this.$buefy.toast.open({
          message: 'You cannot edit this opportunity!',
          type: 'is-warning',
        });
      }
    },

    /**
     * searchOpportunity
     *
     * @param {String} value
     */
    searchOpportunity(value) {
      this.searchClause = value;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.fetchOpportunities();
      }, 800);
    },

    /**
     * updateOpportunity
     *
     */
    async updateOpportunity() {
      const payload = { ...this.opportunity };
      if (this.required && this.closingReason === null) {
        this.isOppClosingReasonModal = true;
      } else {
        try {
          this.opportunityStages.forEach((stage) => {
            if (stage.orderSequence === parseInt(payload.stageId, 10)) {
              payload.stageId = stage.id;
            }
          });

          payload.projectFolderId = [];

          payload.typeId = null;

          payload.opportunityTypes = this.opportunity.typeId ? this.opportunity.typeId.map((i) => ({
            lookupId: i,
          })) : null;

          /*
         * Fetch tags from payload
         */
          if (payload.tags) {
          /*
           * If a tag is an object extract its tag property value, otherwise
           * extract its string value.
           *
           * A tag is an object if it already exists in the database
           */
            const tags = payload.tags.map((t) => t.tag || t);
            const uniqueTags = [...new Set(tags)]; // remove duplicate tags
            payload.tags = uniqueTags;
          } else {
            payload.tags = [];
          }

          await this.$store.dispatch('Opportunities/updateOpportunity', {
            opportunityId: this.selectedOpportunityId,
            opportunity: payload,
          });
          await this.fetchOpportunities();
          this.selectedOpportunityId = '';
          this.isEditing = false;
          this.rowInEdit = {};
          this.$store.commit('Opportunities/CLEAR_OPPORTUNITY');
        } catch (error) {
          console.error(error);
          this.$buefy.toast.open({
            message: error.message,
            type: 'is-warning',
          });
        }
      }
    },

    /**
     * cancelUpdate
     *
     */
    async cancelUpdate() {
      try {
        // await this.fetchOpportunities();
        const newIndex = this.editingIndex;

        this.opportunities = this.opportunities.content.map((item, ind) => {
          if (newIndex === ind) {
            const newItem = { ...item };
            const keys = Object.keys(item);
            keys.forEach((key) => {
              if (key.startsWith('customField') && key.endsWith('Arr')) {
                delete newItem[key];
              }
            });

            return {
              ...newItem,
            };
          }
          return item;
        });

        this.selectedOpportunityId = '';
        this.isEditing = false;
        this.rowInEdit = {};
        this.$store.commit('Opportunities/CLEAR_OPPORTUNITY');
      } catch (error) {
        console.error(error);
      }
    },

    getContactsAsyncData: debounce(async function (token) {
      try {
        const response = await this.$store.dispatch(
          'Search/searchAccountContacts',
          {
            token,
            accountId: this.$store.state.Opportunities.opportunity.accountId,
          },
        );
        this.contactsSearchResults = response;
      } catch (error) {
        this.contactsSearchResults = [];
      }
    }, 500),

    openModal() {
      this.isComponentModalActive = true;
      const checkedOpportunities = this.checkedRows.length > 0 ? this.checkedRows : [];
      const opportunityIDList = checkedOpportunities.map(
        (opportunity) => opportunity.id,
      );
      this.formProps.dataIDList = opportunityIDList;
    },

    async closeModal() {
      this.isComponentModalActive = false;

      /* START: FORCE PAGE RELOAD ON CLOSING MODAL */
      await this.fetchOpportunities();
      setTimeout(async () => {
        await this.fetchOpportunities();
      }, 300);
      /* END: FORCE PAGE RELOAD ON CLOSING MODAL */
    },
  },
};
</script>

<style lang="css" scoped></style>
