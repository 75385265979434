<template>
  <div>
    <!-- page header component -->
    <page-header>
      <template #title>
        <b-tooltip
          v-if="hasRole('ROLE_BUSINESS_ADMIN')
            || checkOwnership()"
          type="is-primary"
          label="Click to edit this contact"
          position="is-bottom"
        >
          <router-link
            tag="h1"
            class="is-capitalized has-text-primary title is-pointer"
            :to="{ name: 'EditContact', params: { contactId: contact.id } }"
          >
            <b-icon
              icon="edit"
              type="is-info"
              size="is-medium"
            />
            {{ contact.firstName }} {{ contact.lastName }}
          </router-link>
        </b-tooltip>

        <h1
          v-else
          class="title has-text-primary is-capitalized"
        >
          <b-icon icon="account-multiple" />
          <slot name="link" /> {{ contact.firstName }} {{ contact.lastName }}
        </h1>
      </template>

      <template #actions>
        <div class="level-item">
          <search-bar />
        </div>

        <div
          v-if="hasRole('ROLE_BUSINESS_ADMIN')
            || checkOwnership()"
          class="level-item"
        >
          <b-dropdown aria-role="list">
            <b-button
              v-if="contact.id"
              slot="trigger"
              type="is-info"
              icon-left="plus"
              icon-right="chevron-down"
            >
              create
            </b-button>
            <template v-if="hasPermissions('create_accounts')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="$router.push(`/accounts/add?contactId=${contact.id}`)"
              >
                Account
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="
                  $router.push(
                    // eslint-disable-next-line max-len
                    `/leads/add?contactId=${contact.id}&contact=${contact.firstName + ' ' + contact.lastName}&accountId=${contact.accountId}`
                  )
                "
              >
                Lead
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_leads')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="
                  $router.push(
                    // eslint-disable-next-line max-len
                    `/opportunities/add?contactId=${contact.id}&contact=${contact.firstName + ' ' + contact.lastName}&accountId=${contact.accountId}`,
                  )
                "
              >
                Opportunity
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <template v-if="hasPermissions('create_activities')">
              <b-dropdown-item
                aria-role="listitem"
                class="has-text-grey"
                @click="
                  $router.push(
                    // eslint-disable-next-line max-len
                    `/activities/add?contactId=${contact.id}&contact=${contact.firstName}`,
                  )
                "
              >
                Activity
              </b-dropdown-item>
              <hr class="dropdown-divider">
            </template>

            <b-dropdown-item
              v-if="hasPermissions('create_documents')"
              aria-role="listitem"
              class="has-text-grey"
              @click="openSidebar"
            >
              Document
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </page-header>

    <!-- loading component -->
    <b-loading
      v-model="isLoading"
      :is-full-page="true"
    />

    <section class="section">
      <div class="container">
        <b-breadcrumb size="is-small">
          <b-breadcrumb-item
            tag="router-link"
            to="/profile"
          >
            Home
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/contacts"
          >
            Contacts
          </b-breadcrumb-item>
          <b-breadcrumb-item
            tag="router-link"
            to="/"
            active
          >
            {{ contact.prefix }} {{ contact.firstName }} {{ contact.lastName }}
          </b-breadcrumb-item>
        </b-breadcrumb>

        <b-tabs v-model="activeTab">
          <b-tab-item label="overview">
            <div class="columns">
              <div class="column is-three-fifths">
                <div class="content">
                  <h4 class="title is-uppercase has-text-primary">
                    contact information
                  </h4>
                </div>

                <div class="columns">
                  <div class="column is-half">
                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <article class="media">
                            <figure class="media-left">
                              <p class="image is-64x64">
                                <img
                                  style="width: 64px; height: 64px;"
                                  class="is-rounded"
                                  :src="contact.contactPhoto ? contact.contactPhoto : defaultImage"
                                  alt="user"
                                >
                              </p>
                            </figure>
                          </article>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>Title: </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          {{ contact.title }}
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>Name: </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          {{ contact.prefix }} {{ contact.firstName }} {{ contact.lastName }}
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>Email: </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <a :href="`mailto:${contact.emailAddress}`">{{ contact.emailAddress }}</a>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>Phone: </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          {{ contact.phone }}
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>Cell: </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          {{ contact.phoneWork }}
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>Birthday: </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          {{ contact.dob }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="divider">

                <div class="columns">
                  <div
                    class="column is-two-thirds-tablet"
                  >
                    <div class="content">
                      <h4 class="subtitle has-text-primary is-uppercase">
                        Custom Fields
                      </h4>
                    </div>
                    <div
                      v-for="(item, key) in customFieldsList"
                      :key="key"
                      class="level is-mobile"
                    >
                      <div class="level-left">
                        <div class="level-item">
                          <p class="has-text-weight-bold is-capitalized">
                            {{ item.label }}
                          </p>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span
                            v-if="isLoading"
                            style="width: 120px"
                          >
                            <b-skeleton :animated="true" />
                          </span>

                          <template v-else>
                            <span
                              v-if="contact.customFieldEntries.length > 0"
                              class="has-text-grey"
                            >
                              <template
                                v-if="contact.customFieldEntries
                                  .filter((i) => i.customField.id === item.id).length > 0"
                              >
                                <template v-if="item.type === 'CURRENCY'">
                                  {{ contact.customFieldEntries.filter(
                                    (i) => i.customField.id === item.id)[0].value | currency }}
                                </template>
                                <template v-else>
                                  {{ contact.customFieldEntries
                                    .filter((i) => i.customField.id === item.id)[0].value
                                    .split(',').length > 1
                                    ? contact.customFieldEntries
                                      .filter((i) => i.customField.id === item.id)[0]
                                      .value.split(',').join(', ')
                                    : contact.customFieldEntries
                                      .filter((i) => i.customField.id === item.id)[0].value }}
                                </template>
                              </template>
                              <template
                                v-else
                              >
                                {{ '--' }}
                              </template>
                            </span>
                            <span
                              v-else
                              class="has-text-grey"
                            >
                              {{ '--' }}
                            </span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr class="divider">

                <div class="content">
                  <h4 class="title is-uppercase has-text-primary">
                    address information
                  </h4>
                </div>

                <div class="columns">
                  <div class="column is-three-fifths">
                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>
                            Street 1
                          </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span class="has-text-grey has-text-weight-semibold">
                            {{ contact.address.street1 }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>
                            Street 2
                          </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          {{ contact.address.street2 }}
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>
                            Street 3
                          </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span class="has-text-grey has-text-weight-semibold">
                            {{ contact.address.street3 }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>
                            City
                          </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span class="has-text-grey has-text-weight-semibold">
                            {{ contact.address.city }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>
                            State
                          </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span class="has-text-grey has-text-weight-semibold">
                            {{ contact.address.state }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="level is-mobile">
                      <div class="level-left">
                        <div class="level-item">
                          <strong>
                            Zip
                          </strong>
                        </div>
                      </div>

                      <div class="level-right">
                        <div class="level-item">
                          <span class="has-text-grey has-text-weight-semibold">
                            {{ contact.address.zip }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column">
                <activities-tabs
                  v-if="hasRole('ROLE_BUSINESS_ADMIN')
                    || checkOwnership()"
                  @view-more="activeTab = 4"
                />

                <!-- TODO: Enable on dev env -->
                <div v-if="false">
                  <div class="columns">
                    <div class="column is-three-fifths">
                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <strong>
                              Created Time
                            </strong>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <time v-if="contact.createdAt">{{ contact.createdAt | date }}</time>
                          </div>
                        </div>
                      </div>

                      <div class="level is-mobile">
                        <div class="level-left">
                          <div class="level-item">
                            <strong>
                              Modified Time
                            </strong>
                          </div>
                        </div>

                        <div class="level-right">
                          <div class="level-item">
                            <time v-if="contact.updatedAt">{{ contact.updatedAt | date }}</time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab-item>

          <b-tab-item label="accounts">
            <accounts-table />
          </b-tab-item>

          <b-tab-item
            :visible="hasRole('ROLE_BUSINESS_ADMIN')
              || checkOwnership()"
            label="leads"
          >
            <leads-table />
          </b-tab-item>

          <b-tab-item
            :visible="hasRole('ROLE_BUSINESS_ADMIN')
              || checkOwnership()"
            label="opportunities"
          >
            <opportunities-table />
          </b-tab-item>

          <b-tab-item
            :visible="hasRole('ROLE_BUSINESS_ADMIN')
              || checkOwnership()"
            label="activities"
          >
            <activities-table />
          </b-tab-item>

          <b-tab-item
            :visible="hasRole('ROLE_BUSINESS_ADMIN')
              || checkOwnership()"
            label="Documents"
          >
            <documents-table
              @edit-document="editDocument"
              @share-document="shareDocument"
            />

            <!-- documents form component -->
            <b-sidebar
              v-model="isSidebarActive"
              type="is-white"
              :fullheight="true"
              :overlay="false"
              :right="true"
            >
              <documents-form
                :files-list="filesList"
                @remove-file="removeFile"
                @clear-files="clearFiles"
                @save-files="createFiles"
              />
            </b-sidebar>

            <!-- share link modal component -->
            <b-modal
              v-model="isShareLinkModalActive"
              scroll="keep"
              :width="400"
            >
              <share-link-modal
                @share-document="shareLink"
                @close="isShareLinkModalActive = false"
              />
            </b-modal>
          </b-tab-item>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import defaultImage from '@/assets/images/avatar.svg';

import DocumentsMixin from '@/mixins/DocumentsMixin';

import { PageHeader } from '@/components/Shared';
import { SearchBar } from '@/components/Search';
import { AccountsTable } from '@/components/Accounts';
import { LeadsTable } from '@/components/Leads';
import { OpportunitiesTable } from '@/components/Opportunities';
import { DocumentsTable, DocumentsForm } from '@/components/Documents';
import { ActivitiesTable, ActivitiesTabs } from '@/components/Activities';

export default {
  name: 'EditContact',

  components: {
    PageHeader,
    SearchBar,
    AccountsTable,
    LeadsTable,
    OpportunitiesTable,
    DocumentsTable,
    DocumentsForm,
    ActivitiesTable,
    ActivitiesTabs,
  },

  filters: {
    date(value) {
      return new Date(value).toLocaleDateString();
    },
  },

  mixins: [DocumentsMixin],

  data: () => ({
    isLoading: true,
    defaultImage,
    page: 1,
    perPage: 50,
    isSidebarActive: false,
    activeTab: 0,
  }),

  computed: {
    ...mapGetters({
      contacts: 'DataGrids/getContacts',
      contact: 'Contacts/getContact',
      customFieldsList: 'Settings/getContactCustomFields',
      settings: 'Settings/getSettings',
    }),
  },

  async created() {
    try {
      await this.$store.dispatch('Settings/fetchCustomizations');
      await this.$store.dispatch('Contacts/fetchContact', this.$route.params.contactId);
      await this.$store.dispatch('Settings/fetchCustomFields', 'Contact');
      await this.$store.dispatch('DataGrids/fetchContacts', {
        page: 0,
        size: 1,
        sort: ['updatedAt', 'desc'],
        filters: `&${new URLSearchParams({ id: this.$route.params.contactId }).toString()}`,
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  mounted() {
    this.setTab(parseInt(this.$route.query.activeTab, 10));
  },

  destroyed() {
    this.$store.commit('Contacts/CLEAR_CONTACT');
  },

  methods: {
    openSidebar() {
      this.activeTab = 5;
      this.isSidebarActive = true;
    },

    setTab(value) {
      this.activeTab = value;
    },

    checkOwnership() {
      if (this.settings.allRead) {
        return true;
      }
      if (this.contacts.length > 0) {
        const { teamIds } = this.contacts[0];
        if (teamIds) {
          return this.isContactOwner(this.contact.ownerId)
              || this.isContactManager(this.contact.ownerId)
              || this.isTeammate(teamIds);
        }
        return this.isContactOwner(this.contact.ownerId);
      }
      return false;
    },
  },

};
</script>

<style></style>
