<template>
  <!--TODO: Refactor UI form to match the one in components/Accounts/AccountsFormModal -->
  <div class="card is-primary">
    <header class="card-header">
      <p class="card-header-title is-uppercase">
        <b-icon
          icon="user"
          type="is-white"
        />
        <span class="is-padded-left">new account</span>
      </p>
      <b-button
        icon-right="close"
        type="is-text"
        class="has-text-info"
        @click="$emit('close')"
      />
    </header>
    <div class="card-content">
      <!-- loading component -->
      <b-loading
        v-model="isLoading"
        :is-full-page="false"
        :can-cancel="false"
      />
      <!-- account form modal component -->
      <validation-observer
        ref="accountForm"
        v-slot="{ invalid, handleSubmit }"
      >
        <form @submit.prevent="handleSubmit(createAccount)">
          <div class="columns">
            <div class="column">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="account name"
              >
                <b-field
                  label="Account Name"
                  label-for="account name"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="name" />
                </b-field>
              </validation-provider>
            </div>
            <div class="column">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="type"
              >
                <b-field
                  class="is-primary is-required"
                  label="Account Type"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-select
                    v-model="typeId"
                    expanded
                  >
                    <option
                      v-for="(accountType, index) in accountTypes"
                      :key="index"
                      :value="accountType.id"
                    >
                      {{ accountType.value }}
                    </option>
                  </b-select>
                </b-field>
              </validation-provider>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <validation-provider
                v-slot="{ errors, valid }"
                rules="required"
                name="description"
              >
                <b-field
                  label="Description"
                  class="is-primary is-required"
                  :type="{ 'is-danger': errors[0], 'is-success': valid }"
                  :message="errors"
                >
                  <b-input v-model="description" />
                </b-field>
              </validation-provider>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                :rules="typeId === 105 ? 'required' : ''"
                name="owner"
              >
                <b-field
                  label="Owner"
                  label-for="owner"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                  :class="typeId === 105 ? 'is-primary is-required' : 'is-primary'"
                >
                  <b-autocomplete
                    id="owner"
                    v-model="owner"
                    icon-right="search"
                    expanded
                    field="fullName"
                    :loading="isLoadingName"
                    :data="ownerSearchResults"
                    @typing="getOwnersAsyncData"
                    @select="(option) => (selectedOwner = option)"
                  >
                    <template slot-scope="props">
                      <div class="media">
                        <div class="media-content">
                          {{ props.option.fullName.trim() }}
                          <br>
                          <small>
                            email: {{ props.option.email }} <br>
                            Phone: {{ props.option.phoneCell }}
                          </small>
                        </div>
                      </div>
                    </template>

                    <template #empty>
                      No results for {{ owner }}
                    </template>
                  </b-autocomplete>
                </b-field>
              </validation-provider>
            </div>

            <div class="column">
              <b-field
                label="CO-Owner"
                label-for="coOwner"
                class="is-primary"
              >
                <b-autocomplete
                  id="coOwner"
                  v-model="coOwner"
                  icon-right="search"
                  expanded
                  field="fullName"
                  :loading="isLoadingName"
                  :data="coOwnerSearchResults"
                  @typing="getCoOwnersAsyncData"
                  @select="(option) => (selectedCoOwner = option)"
                >
                  <template slot-scope="props">
                    <div class="media">
                      <div class="media-content">
                        {{ props.option.fullName.trim() }}
                        <br>
                        <small>
                          email: {{ props.option.email }} <br>
                          Phone: {{ props.option.phoneCell }}
                        </small>
                      </div>
                    </div>
                  </template>

                  <template #empty>
                    No results for {{ coOwner }}
                  </template>
                </b-autocomplete>
              </b-field>
            </div>
          </div>

          <div class="columns is-vcentered">
            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                rules="integer|max_value:100"
                name="owner percentage"
              >
                <b-field
                  label="% Split Owner"
                  label-position="outside"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                  class="is-primary"
                >
                  <b-input
                    v-model="ownerPercentage"
                    min="0"
                    max="100"
                    type="number"
                  />
                </b-field>
              </validation-provider>
            </div>

            <div class="column">
              <b-field
                class="is-primary"
              >
                <b-slider
                  v-model="percentage"
                  :min="0"
                  :max="100"
                  type="is-info"
                />
              </b-field>
            </div>

            <div class="column">
              <validation-provider
                v-slot="{ errors }"
                rules="integer|max_value:100"
                name="co owner percentage"
              >
                <b-field
                  label="% Split CO-Owner"
                  label-position="outside"
                  :type="{ 'is-danger': errors[0] }"
                  :message="errors"
                  class="is-primary"
                >
                  <b-input
                    v-model="coOwnerPercentage"
                    min="0"
                    max="100"
                    type="number"
                  />
                </b-field>
              </validation-provider>
            </div>
          </div>

          <hr class="divider-dashed">

          <div>
            <b-collapse
              class="card"
              animation="slide"
              :open="false"
              aria-id="contactForm"
            >
              <template #trigger="props">
                <header class="card-header contact">
                  <p class="card-header-title is-uppercase">
                    <b-icon
                      icon="user-plus"
                      type="is-white"
                    />
                    <span class="is-padded-left">new contact (optional)</span>
                  </p>

                  <a class="card-header-icon">
                    <b-icon
                      v-show="props.open"
                      pack="fas"
                      icon="chevron-up"
                    />
                    <b-icon
                      v-show="!props.open"
                      pack="fas"
                      icon="chevron-down"
                    />
                  </a>
                </header>
              </template>

              <validation-observer
                ref="accountForm"
                v-slot="{ dirty }"
              >
                <div class="card card-collapse">
                  <form @submit.prevent="">
                    <div class="columns">
                      <div class="column is-half">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          :rules="{required: dirty && contactFormValid}"
                          name="first name"
                        >
                          <b-field
                            label="First Name"
                            class="is-primary"
                            :class="{'is-required' : contactFormValid}"
                            :type="{
                              'is-danger': errors[0],
                              'is-success': valid && dirty && firstName !== '' }"
                            :message="errors"
                          >
                            <b-input v-model="firstName" />
                          </b-field>
                        </validation-provider>
                      </div>

                      <div class="column">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          :rules="{required: dirty && contactFormValid}"
                          name="last name"
                        >
                          <b-field
                            label="Last Name"
                            class="is-primary"
                            :class="{'is-required' : contactFormValid}"
                            :type="{
                              'is-danger': errors[0],
                              'is-success': valid && dirty && lastName !== '' }"
                            :message="errors"
                          >
                            <b-input v-model="lastName" />
                          </b-field>
                        </validation-provider>
                      </div>
                    </div>

                    <div class="columns">
                      <div class="column is-half">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          :rules="{required: dirty && contactFormValid, email}"
                          name="email"
                        >
                          <b-field
                            label="Email"
                            class="is-primary"
                            :class="{'is-required' : contactFormValid}"
                            :type="{
                              'is-danger': errors[0],
                              'is-success': valid && dirty && emailAddress !== '' }"
                            :message="errors"
                          >
                            <b-input v-model="emailAddress" />
                          </b-field>
                        </validation-provider>
                      </div>
                      <div class="column is-half">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          :rules="{required: dirty && contactFormValid}"
                          name="phone"
                        >
                          <b-field
                            label="Phone"
                            class="is-primary"
                            :class="{'is-required' : contactFormValid}"
                            :type="{
                              'is-danger': errors[0],
                              'is-success': valid && dirty && phone !== '' }"
                            :message="errors"
                          >
                            <vue-tel-input v-model="phone" />
                          </b-field>
                        </validation-provider>
                      </div>
                    </div>
                  </form>
                </div>
              </validation-observer>
            </b-collapse>
          </div>

          <div class="form-btns">
            <b-field>
              <div class="buttons">
                <b-button
                  native-type="submit"
                  type="is-success"
                  :disabled="invalid"
                >
                  Save
                </b-button>
                <b-button
                  type="is-info"
                  @click="$emit('close')"
                >
                  cancel
                </b-button>
              </div>
            </b-field>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

import debounce from '@/utils/debounce';

export default {
  name: 'AccountsFormModal',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    moduleName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isLoading: false,
    isLoadingName: false,
    selectedOwner: null,
    selectedCoOwner: null,
    ownerSearchResults: [],
    coOwnerSearchResults: [],
  }),
  computed: {
    ...mapGetters({
      account: 'Accounts/getAccount',
      contact: 'Contacts/getContact',
      users: 'Users/getUsers',
      status: 'Lookups/getAccountStatuses',
      accountTypes: 'Lookups/getAccountTypes',
    }),

    percentage: {
      get() {
        return this.$store.state.Accounts.account.owner.percentage;
      },

      set(value) {
        this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', value);
      },
    },

    name: {
      get() {
        return this.$store.state.Accounts.account.name;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_NAME', value);
      },
    },

    owner: {
      get() {
        return this.$store.state.Accounts.account.owner.owner;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_OWNER', value);
      },
    },

    ownerPercentage: {
      get() {
        return this.$store.state.Accounts.account.owner.percentage;
      },

      set(value) {
        this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', value);
      },
    },

    coOwner: {
      get() {
        return this.$store.state.Accounts.account.coOwner.owner;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER', value);
      },
    },

    coOwnerPercentage: {
      get() {
        return this.$store.state.Accounts.account.coOwner.percentage;
      },

      set(value) {
        this.$store.commit('Accounts/SET_ACCOUNT_OWNER_PERCENTAGE', 100 - value);
        return this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_PERCENTAGE', value);
      },
    },

    typeId: {
      get() {
        return this.$store.state.Accounts.account.typeId;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_TYPE_ID', value);
      },
    },

    description: {
      get() {
        return this.$store.state.Accounts.account.description;
      },

      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_DESCRIPTION', value);
      },
    },

    statusId: {
      get() {
        return this.$store.state.Accounts.account.statusId;
      },
      set(value) {
        return this.$store.commit('Accounts/SET_ACCOUNT_STATUS', value);
      },
    },

    firstName: {
      get() {
        return this.$store.state.Contacts.contact.firstName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_FIRST_NAME', value);
      },
    },

    lastName: {
      get() {
        return this.$store.state.Contacts.contact.lastName;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_LAST_NAME', value);
      },
    },
    emailAddress: {
      get() {
        return this.$store.state.Contacts.contact.emailAddress;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_EMAIL_ADDRESS', value);
      },
    },
    phone: {
      get() {
        return this.$store.state.Contacts.contact.phone;
      },
      set(value) {
        return this.$store.commit('Contacts/SET_CONTACT_PHONE', value);
      },
    },

    contactFormValid() {
      if (this.firstName !== '' || this.lastName !== '' || this.emailAddress !== '' || this.phone !== '') {
        return true;
      }
      return false;
    },
  },

  watch: {
    selectedAccount(value) {
      this.$router.push(`/accounts/${value.accountId}/view`);
    },

    selectedParent(value) {
      return this.$store.commit('Accounts/SET_ACCOUNT_PARENT_ID', value.accountId);
    },

    selectedOwner(value) {
      return this.$store.commit('Accounts/SET_ACCOUNT_OWNER_ID', value.userId);
    },

    selectedCoOwner(value) {
      return this.$store.commit('Accounts/SET_ACCOUNT_CO_OWNER_ID', value.userId);
    },

    typeId(value) {
      if (value !== 105 && this.$route.name === 'AddAccount') {
        this.clearOwner();
      } else {
        this.$store.commit('Accounts/SET_ACCOUNT_OWNER', localStorage.getItem('full_name'));
        this.$store.commit('Accounts/SET_ACCOUNT_OWNER_ID', localStorage.getItem('user_id'));
      }
    },
  },

  async created() {
    try {
      if (this.hasPermissions('read_lookups')) {
        await this.fetchAccountStatuses();
        await this.fetchAccountTypes();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },
  beforeDestroy() {
    this.$store.commit('Accounts/CLEAR_ACCOUNT');
  },
  mounted() {
    if (this.$route.query.accountId) {
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', this.$route.query.accountId);
      this.$store.commit('Contacts/SET_CONTACT_ACCOUNT', this.$route.query.account);
    }
  },
  methods: {
    ...mapActions({
      fetchAccountStatuses: 'Lookups/fetchAccountStatuses',
      fetchAccountTypes: 'Lookups/fetchAccountTypes',
    }),

    async createContact() {
      this.isLoading = true;
      const payload = { ...this.contact };
      try {
        const response = await this.$store.dispatch('Contacts/createContact', payload);
        if (this.moduleName === 'leads') {
          this.$store.commit('Leads/SET_LEAD_PRIMARY_CONTACT_ID', response.id);
        } else {
          this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT_ID', response.id);
          this.$store.commit('Opportunities/SET_OPPORTUNITY_PRIMARY_CONTACT', `${response.firstName} ${response.lastName}`);
        }
        this.$emit('close');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    async createAccount() {
      this.isLoading = true;
      const payload = { ...this.account };
      if (payload.coOwner.ownerId === '') {
        payload.coOwner = null;
      }

      if (payload.companyInformation.industryId === '') {
        payload.companyInformation.industryId = 120;
      }

      try {
        const response = await this.$store.dispatch('Accounts/createAccount', payload);
        if (this.moduleName === 'leads') {
          this.$store.commit('Leads/SET_LEAD_ACCOUNT_ID', response.id);
        } else {
          this.$store.commit('Opportunities/SET_OPPORTUNITY_ACCOUNT_ID', response.id);
          this.$store.commit('Opportunities/SET_OPPORTUNITY_ACCOUNT', response.name);
        }
        this.$store.commit('Contacts/SET_CONTACT_ACCOUNT_ID', response.id);
        if (this.contact.firstName !== '') {
          this.createContact();
        }
        this.$emit('close');
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },

    getOwnersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.ownerSearchResults = response;
      } catch (error) {
        this.ownerSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),

    getCoOwnersAsyncData: debounce(async function (token) {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch('Search/searchUsers', token);
        this.coOwnerSearchResults = response;
      } catch (error) {
        this.coOwnerSearchResults = [];
      } finally {
        this.isLoading = false;
      }
    }, 500),
  },
};
</script>

<style lang="css" scoped>
hr.divider-dashed {
  border-bottom: 3px dashed #cccccc;
  background: transparent;
  padding: 0;
  margin: 0.6rem 0;
}
.card.card-collapse {
  background: #1F3D73;
}
.card-header.contact {
  padding-bottom: 0.5rem;
}
.column {
  padding: 0.2rem 0.75rem;
}
.form-btns {
  margin-top: 1rem;
}
</style>
